import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess, ConvertDateFromDB, ConvertDateToDB} from 'src/globals';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { cPackage } from 'src/types/package';

import { cDiscount } from 'src/types/discounts';
import { discount_returndata } from 'src/types/discount_returndata';
import { package_returndata } from 'src/types/package_returndata';
import { v4 } from 'uuid';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-discounts',
  templateUrl: './discountsedit.component.html',
  styleUrls: ['./discountsedit.component.css']
})

export class DiscountsEditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Discount: cDiscount;
  httpResponse: any;
  id: string;
  stateOptions: any[];
  stateOptions1: any[];
  stateOptions2: any[];
  stateOptions3: any[];
  discountNameValid: boolean = true;
  pageReady: boolean = true;
  discountStartDateValid: boolean = true;
  discountEndDateValid: boolean = true;
  discountDateOrder: boolean = true;
  ListPackages:any;
  packagesValid: boolean = true;
  startDate: Date;
  endDate: Date;
  discountCodeValid: boolean = true;
  activeIndex: number;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "-";
  

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) {
    this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    this.stateOptions1 = [{ label: 'Venue Only', value: "VENUE" }, { label: 'Public Only', value: "PUBLIC" }, { label: 'Both', value: "BOTH" }];
    this.stateOptions2 = [{ label: 'All Packages', value: true }, { label: 'Selected Packages', value: false }];
    this.stateOptions3 = [{ label: 'Fixed Amount', value: "FIXED" }, { label: 'Percentage', value: "PERCENTAGE" }];

    this.startDate = new Date();

    this.endDate = new Date();
    this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() + 365));

    this.Discount = {
      id: null,
      discountid: null,  
      autogeneratecodes: false,
      numberofautogeneratedcodes: 0,
      name: "", 
      code: "",
      discounttype: "PERCENTAGE",
      discountamount: 0,
      visibility: "BOTH",
      startdate: this.startDate,
      enddate: this.endDate,
      availablealldays: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      numberofuses: 0,
      useonallpackages: true,
      packages: [],
      disabled:  false,
      venueid: this.VenueID,
      redeemdate: null,
      isgenerated: false,
      isEarlyBird: false
    }

  }
  
  ngOnInit(): void {

    this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("DISCOUNTS", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');

          this.http.get(bmxurl + "/discount/" + this.VenueID + "/" + this.id).subscribe(response => {
            this.httpResponse = (response as discount_returndata)
            if (this.httpResponse.succeeded)
            {
              this.Discount = {
                id: (this.httpResponse.data as cDiscount).id,
                discountid: (this.httpResponse.data as cDiscount).discountid,
                autogeneratecodes: (this.httpResponse.data as cDiscount).autogeneratecodes,
                numberofautogeneratedcodes: (this.httpResponse.data as cDiscount).numberofautogeneratedcodes,
                name: (this.httpResponse.data as cDiscount).name, 
                code: (this.httpResponse.data as cDiscount).code,
                discounttype: (this.httpResponse.data as cDiscount).discounttype,
                discountamount: (this.httpResponse.data as cDiscount).discountamount,
                visibility: (this.httpResponse.data as cDiscount).visibility,
                startdate: (this.httpResponse.data as cDiscount).startdate,
                enddate: (this.httpResponse.data as cDiscount).enddate,
                availablealldays: (this.httpResponse.data as cDiscount).availablealldays,
                monday: (this.httpResponse.data as cDiscount).monday,
                tuesday: (this.httpResponse.data as cDiscount).tuesday,
                wednesday: (this.httpResponse.data as cDiscount).wednesday,
                thursday: (this.httpResponse.data as cDiscount).thursday,
                friday: (this.httpResponse.data as cDiscount).friday,
                saturday: (this.httpResponse.data as cDiscount).saturday,
                sunday: (this.httpResponse.data as cDiscount).sunday,
                numberofuses: (this.httpResponse.data as cDiscount).numberofuses,
                useonallpackages: (this.httpResponse.data as cDiscount).useonallpackages,
                packages: (this.httpResponse.data as cDiscount).packages,
                disabled: (this.httpResponse.data as cDiscount).disabled,
                venueid: (this.httpResponse.data as cDiscount).venueid,
                redeemdate: (this.httpResponse.data as cDiscount).redeemdate,
                isgenerated: (this.httpResponse.data as cDiscount).isgenerated,
                isEarlyBird: (this.httpResponse.data as cDiscount).isEarlyBird
              };
      
              this.startDate = new Date(this.Discount.startdate);
              this.endDate = new Date(this.Discount.enddate);
      
              this.pageReady = true;
            
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting discount'});
              this.pageReady = false;
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting discount, error:' + error.messages[0]});
            this.pageReady = false;
          });
      
          //Get packages
          this.http.get(bmxurl + "/packagelist/" + this.VenueID + "/false").subscribe(response => {
            if((response as package_returndata).succeeded == true)
            {
              this.ListPackages = (response as package_returndata).data;
      
              if ((response as package_returndata).messages.length > 0)
              {
                (response as package_returndata).messages.forEach(error => {
                  this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
                });
              }
            }
          }, error => {
          });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });



  }

  updateData() {
    if ([this.DoDateValidCheck(), this.DoValidCheck(), this.DoDateCheck()].every(x => x == true)) {

      this.Discount.startdate = ConvertDateToDB(this.startDate);
      this.Discount.enddate = ConvertDateToDB(this.endDate);

      this.Discount.code = this.Discount.code.trim();

      if (this.id == "0") {
        this.http.post<cDiscount>(bmxurl + "/discount", this.Discount).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Discount successfully created' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages[0] });
        })
      }
      else {
        this.Discount.disabled = false;

        this.http.put<cDiscount>(bmxurl + "/discount", this.Discount).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Discount successfully updated' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
        })
      }
    }
    else
    {
      if(!this.discountNameValid)
      {
        let discountname = document.getElementById("discountname") as HTMLElement;
        discountname.scrollIntoView({behavior: "smooth"});
      }
      if(!this.discountStartDateValid)
      {
        let discountstartdate = document.getElementById("discountstartdate") as HTMLElement;
        discountstartdate.scrollIntoView({behavior: "smooth"});
      }
      if(!this.discountEndDateValid)
      {
        let discountenddate = document.getElementById("discountenddate") as HTMLElement;
        discountenddate.scrollIntoView({behavior: "smooth"});
      }
      if(!this.discountDateOrder)
      {
        let discountstartdate = document.getElementById("discountstartdate") as HTMLElement;
        discountstartdate.scrollIntoView({behavior: "smooth"});
      }
    }
  }

  DoDateCheck()
  {
    let AllValid = true;

    let dEndDate = new Date(this.endDate);
    if(this.startDate > this.addDays(dEndDate, 1))
    {
      AllValid = false;
      this.discountDateOrder = false;
    }
    else
    {
      this.discountDateOrder = true;
    }

    return AllValid
  }

  DoDateValidCheck()
  {
    let AllValid = true;

    if(this.startDate == null)
    {
      AllValid = false;
      this.discountStartDateValid = false;
    }
    else
    {
      this.discountStartDateValid = true;
    }

    if(this.endDate == null)
    {
      AllValid = false;
      this.discountEndDateValid = false;
    }
    else
    {
      this.discountEndDateValid = true;
    }

    return AllValid;
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  DoValidCheck() {
    let AllValid = true;

    if (this.Discount.name == null || this.Discount.name == "") {
      AllValid = false;
      this.discountNameValid = false;
      if (this.activeIndex == 1)
      {
        //this.messageService.add({severity:'error', summary:'Error', detail:'You need to add a Discount Name'});
        this.activeIndex = 0;
      }
    }
    else {
      this.discountNameValid = true;
    }

    if(this.Discount.code == "" && this.Discount.autogeneratecodes == false)
    {
      this.discountCodeValid = false;

      if (this.activeIndex == 1)
      {
        //this.messageService.add({severity:'error', summary:'Error', detail:'You need to add a Discount Code'});
        this.activeIndex = 0;
      }
    }
    else
    {
      this.discountCodeValid = true;
    }

    if(!this.Discount.useonallpackages && this.Discount.packages.length == 0)
    {
      AllValid = false;
      this.packagesValid = false;
    }
    else
    {
      this.packagesValid = true;
    }
    
    return AllValid;
  }

  returnToList() {
    this.router.navigateByUrl("/discounts");
  }

  AddToList(product){
    if(this.Discount.packages == null)
    {
      let newId = v4();
      let newpeId = v4();

      this.Discount.packages = [];
      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.imageurl,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid
      }

      this.Discount.packages.push(newPackage);
    }
    else{
      let newId = v4();
      let newpeId = v4();

      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.imageurl,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid
      }

      this.Discount.packages.push(newPackage);
    }

    this.ListPackages = this.ListPackages.filter(obj => obj.id !== product.id);
  }

  RemoveFromList(product){
    this.Discount.packages = this.Discount.packages.filter(obj => obj.id !== product.id);

    this.ListPackages.push(product);

    var sortedArray:any[] = this.ListPackages.sort((n1,n2) => {
      if (n1.name > n2.name) {
          return 1;
      }
  
      if (n1.name < n2.name) {
          return -1;
      }
    });

    this.ListPackages = sortedArray;
  }


  
}
