import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl } from 'src/globals';
import { getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../_services/busy.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';
import { cColumninfo } from 'src/types/columninfo';

@Component({
  selector: 'app-extrasreport',
  templateUrl: './extrasreport.component.html',
  styleUrls: ['./extrasreport.component.css']
})
export class ExtrasreportComponent  implements OnInit {
  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  BookingDate: Date;
  PreviousBookingDate: Date;
  hubConnection: HubConnection;
  httpResponse: any;
  navSubscription: Subscription = null;
  bookingsInProgress: number = 0;
  totalBookingsToday: number = 0;
  inProgress: boolean = false;

  bcItems: MenuItem[];
  home: MenuItem;
  PreviousHubs: string[] = [];
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "-";

  cols: any[] = [];
  
  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        // if(getAccess("EXTRAS", user)){
        //   this.AccessAllowed = true;
        // }
        // else
        // {
        //   this.AccessAllowed = false;
        // }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
    }

  ngOnDestroy() {
    this.hubConnection.stop();
  }

  showInProgress(){
    this.inProgress = true;
    this.loadgrid(false);
  }

  unshowInProgress(){ 
    this.inProgress = false;
    this.loadgrid(false);
  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EXTRAS", user)){
          this.AccessAllowed = true;
        }
        else
        {
          this.AccessAllowed = false;
        }

        localStorage.removeItem("screenMemoryBookingss");
        localStorage.removeItem("screenMemoryEvents");
        
        this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
          this.hubConnection = new HubConnectionBuilder()
          .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
          accessTokenFactory: () => tokenReturned 
        }).build();

        let menuItems: MenuItem[];

        let userid = localStorage.getItem("SmartUserID");
        
        this.BookingDate = new Date();

        let BookingDateYear = this.BookingDate.getFullYear();
        let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
        let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

        let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

        this.PreviousHubs.push(BookingEventDate);

        this.hubConnection.start();

        this.hubConnection.off(BookingEventDate);

        this.hubConnection.on(BookingEventDate, data => {
          this.loadgrid(false);
        });

        this.busyService.busy();
        
            //Get Settings and put them in Local Storage
            this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
              this.httpResponse =  (response as venuesettings_returndata)
              if(this.httpResponse.succeeded)
              {
                //Set Venue Settings ID
                localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
        
                this.httpResponse.data.venueSettings.forEach(element => {
                  localStorage.setItem(element.name, element.value);
                });

                this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
                this.loadgrid(false);
              }
              else{
                this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
            });

        });

        this.http.get(bmxurl + "bookingfee/" + this.VenueID).subscribe(response => {
          this.httpResponse = (response as bookingfee_returndata)
          if (this.httpResponse.succeeded)
          {
            if(this.httpResponse.data != null)
            {
              let BookingFee: cBookingfee = {
                id: (this.httpResponse.data as cBookingfee).id,
                venueid: (this.httpResponse.data as cBookingfee).venueid,
                disabled: (this.httpResponse.data as cBookingfee).disabled,
                bfid: (this.httpResponse.data as cBookingfee).bfid,
                bfpublicapply: (this.httpResponse.data as cBookingfee).bfpublicapply,
                bfadminapply: (this.httpResponse.data as cBookingfee).bfadminapply,
                bfpublicshowtext: (this.httpResponse.data as cBookingfee).bfpublicshowtext,
                bfpublictext: (this.httpResponse.data as cBookingfee).bfpublictext,
                bfpublictype: (this.httpResponse.data as cBookingfee).bfpublictype,
                bfadmintype: (this.httpResponse.data as cBookingfee).bfadmintype,
                bfpublicfee: (this.httpResponse.data as cBookingfee).bfpublicfee,
                bfpublicmin: (this.httpResponse.data as cBookingfee).bfpublicmin,
                bfpublicmax: (this.httpResponse.data as cBookingfee).bfpublicmax,
                bfadminfee: (this.httpResponse.data as cBookingfee).bfadminfee,
                bfadminmin: (this.httpResponse.data as cBookingfee).bfadminmin,
                bfadminmax: (this.httpResponse.data as cBookingfee).bfadminmax
              };

              localStorage.setItem("VenueBookingFee", JSON.stringify(BookingFee));
            }
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee'});
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee, error:' + error.messages[0]});
        });


      }
    });
  }

  editdata(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

  editdatanew(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

  loadgrid(showdisabled: boolean)
  {
    let BookingDateYear = this.BookingDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    //this.busyService.busy();
    this.http.get(bmxurl + "GetBookingLisReport/" + this.VenueID + "/" + dateChosen + "/false").subscribe(response => {
      try{
        this.CreateUpdateResponse = (response as any);

        if(this.CreateUpdateResponse.data.length > 0) 
        {
          let importCols = Object.keys(this.CreateUpdateResponse.data[0]);

          this.cols = [];
          
          importCols.forEach(element => {
            
            let column = new cColumninfo;
            column.field = element;
            column.header = element;

            this.cols.push(column)
         });

        }

        this.ListBookingsGlobal = this.CreateUpdateResponse.data;
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  doneDate(){

    this.inProgress = false;

    this.loadgrid(false)

    this.PreviousHubs.forEach(element => {
      this.hubConnection.off(element);
    });

    this.PreviousHubs = [];

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.PreviousHubs.push(BookingEventDate);

    this.hubConnection.on(BookingEventDate, data => {
      this.loadgrid(false);
    });

  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.BookingDate = this.addDays(new Date(this.BookingDate), num);
    this.doneDate();
  }

  exportData()
  {


  }

}