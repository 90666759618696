<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="Resource && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Resource</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Define your resource here.  Resources can also be composed from other resources.  For example you may have a resource called Trainer 1 and another called Trainer 2.  You could then create a resource called Paddleboard training which is made up of trainer 1 and Trainer 2.  When this resource is used it would block out trainer 1 and trainer 2.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <p-tabView style="width: 100%" [(activeIndex)]="activeIndex">
                    <p-tabPanel header="Resource Details" leftIcon="pi pi-clone" >
                        <div class="field mb-4 col-12">
                            <label for="resourcename" class="font-medium">Resource Name</label>
                            <input [(ngModel)]="Resource.name" id="resourcename" type="text" [ngStyle]="{'border-color': (resourceNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                        </div>
                        <div *ngIf="!resourceNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Resource name.</div>

                            <div class="field mb-4 col-12">
                                <label for="useclasses" class="font-medium">Resource is made up of other resources</label>
                                <p-selectButton id="usesubresources" [style]="{'width': '150px'}" [options]="stateOptionsRes" [(ngModel)]="Resource.isresourcecontainer" (onOptionClick)="ContainerClick($event)"></p-selectButton>
                            </div>

                            <div class="field mb-4 col-12">
                              <label for="exclusive" class="font-medium">Exclusive</label>
                              <p-selectButton id="exclusive" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Resource.exclusive" ></p-selectButton>
                            </div>

                            <div class="field mb-4 col-12">
                                <label for="concurrentUses" class="font-medium">Capacity/Concurrent Uses</label>
                                <p-inputNumber id="concurrentUses" [style]="{'width': '300px'}" [(ngModel)]="Resource.concurrentUses== null ? 0: Resource.concurrentUses" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal" (keyup)="DoValidCheck()"></p-inputNumber>
                            </div>
                            <div *ngIf="!resourceConcurrentValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a number of Concurrent Uses.</div>

                            <div class="field mb-4 col-12"  *ngIf="!Resource.exclusive">
                                <label for="MaxConcurrent" class="font-medium">Maximum uses of this resource at the same time (0 is unlimited)</label>
                                <p-inputNumber id="MaxConcurrent" [style]="{'width': '300px'}" [(ngModel)]="Resource.maxConcurrent" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                            </div>

                            <div class="field mb-4 col-12">
                                <label for="useclasses" class="font-medium">Use Classes</label>
                                <p-selectButton id="useclasses" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Resource.useClasses" ></p-selectButton>
                            </div>

                            <div class="field mb-4 col-12">
                              <label for="useclasses" class="font-medium">Show on Bookings Made Today</label>
                              <p-selectButton id="showonbooking" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Resource.showonbooking" ></p-selectButton>
                          </div>

                            <div *ngIf="Resource.useClasses" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">
                                <p-table [value]="Resource.classes">
                                    <ng-template pTemplate="header">
                                    <tr style="color:Black;background-color:White;font-weight:bold;">
                                        <th scope="col">Class Name</th>
                                        <th scope="col">Capacity</th>
                                    </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-data>
                                    <tr >
                                        <td>{{data.name}}</td>
                                        <td>
                                            <p-inputNumber id="length" [showButtons]="true" max="{{Resource.concurrentUses}}" min="0" [(ngModel)]="data.capacity" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                                        </td>
                                    </tr>  
                                    </ng-template>  
                                </p-table>
                            </div>

                            <div class="field mb-4 col-12">
                                <label for="colour" class="font-medium">Colour</label><br/>
                                <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="Resource.colour"></p-colorPicker>
                                <input style="width:200px;" [(ngModel)]="Resource.colour" id="hexcolour" type="text" pInputText>
                            </div>

                            <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                              <div class="field mb-4 col-12">
                                <label for="" class="font-medium">Tags</label>
                                <br />
                                <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="Resource.tags" display="chip">
                                  <ng-template let-value pTemplate="selectedItems">
                                    <span *ngFor="let tag of Resource.tags">
                                      <p-chip label="{{tag.name}}" styleClass="mr-2" [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }"></p-chip>
                                    </span>
                                    <div *ngIf="!Resource.tags || Resource.tags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                                  </ng-template>
                                </p-multiSelect>
                              </div>
                            </div>
                    </p-tabPanel>
                    <p-tabPanel header="Add Resources" leftIcon="pi pi-sitemap" [disabled]="!Resource.isresourcecontainer">
                        <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                            <div style="float:left; width:32%">
                              <div class="drag-column">
                                <h5> Available Resources </h5>
                                <p-table [value]="allresources">
                                  <ng-template pTemplate="header">
                                    <tr>
                                      <th style="width:80%">Name</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-resource>
                                    <tr>
                                      <td>{{resource.name}}</td>
                                      <td>
                                        <button pButton pRipple type="button" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToResources(resource)">
                                          <span class="pi pi-angle-right"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!--<div *ngIf="availableResources.length == 0" style="padding: 15px;">
                                  <i>No resources available</i>
                                </div>-->
                              </div>
                            </div>
                            <div style="float:right; width:65%">
                              <div class="drop-column">
                                <h5> Selected Resources </h5>
                                <p-table [value]="Resource.resources">
                                  <ng-template pTemplate="header" let-columns>
                                    <tr>
                                      <th>&nbsp;</th>
                                      <th style="width:80%">Name</th>
                                      <th>Exclusive</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-resource let-columns="columns" let-index="rowIndex">
                                    <tr [pReorderableRow]="index">
                                      <td>
                                        <span class="pi pi-bars" pReorderableRowHandle></span>
                                      </td>
                                      <td>{{resource.name}}</td>
                                      <td>
                                        <p-selectButton [style]="{'width': '225px'}" [options]="stateOptionsRes" [(ngModel)]="resource.exclusive"></p-selectButton>
                                      </td>
                                      <td>
                                        <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromResources(resource)">
                                          <span class="pi pi-angle-left"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!---<div *ngIf="selectedResources" style="padding: 15px;">
                                  <i>No resources selected, drag some here.</i>
                                </div>-->
                              </div>
                            </div>
                          </div>

                        
                    </p-tabPanel>
                </p-tabView>

                <div class="col-12"> 
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>