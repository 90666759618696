import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { confirmation_returndata } from 'src/types/confirmation_returndata';
import { cConfimration, confimration } from 'src/types/confirmations';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})

export class ConfirmationComponent implements OnInit {
  ListConfirmations: any =[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  Confirmation: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 
      //this.isRowSelectable = this.isRowSelectable.bind(this);
      
    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CONFIRMATIONS", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(ConfirmationID)
  {
    this.http.delete(bmxurl + "/confirmations/" + this.VenueID + "/" + ConfirmationID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Confirmation successfully disabled'});
        this.router.navigateByUrl("/confirmations");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Confirmation'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "/ConfirmationList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListConfirmations = (response as confirmation_returndata).data;
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Confirmations, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let ConfirmationID = EditData;
    this.router.navigateByUrl("/confirmationeditor/" + ConfirmationID);
  }

  copydata(id)
  {
      this.http.get(bmxurl + "confirmations/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = (response as class_returndata)
        if (this.httpResponse.succeeded)
        {
          this.Confirmation = {
            id: null,
            confirmationid: null,
            name: (this.httpResponse.data as cConfimration).name,
            subject: (this.httpResponse.data as cConfimration).subject,
            disabled: (this.httpResponse.data as cConfimration).disabled,
            venueid:(this.httpResponse.data as cConfimration).venueid,
            emailsubject:(this.httpResponse.data as cConfimration).emailsubject,
            showconfirmationmessage: (this.httpResponse.data as cConfimration).showconfirmationmessage,
            confirmationmessage: (this.httpResponse.data as cConfimration).confirmationmessage,
            paymentmessagetype: (this.httpResponse.data as cConfimration).paymentmessagetype,
            paymentmessage:(this.httpResponse.data as cConfimration).paymentmessage,
            howtofindustype:(this.httpResponse.data as cConfimration).howtofindustype,
            howtofindustext:(this.httpResponse.data as cConfimration).howtofindustext,
            webaddressformap:(this.httpResponse.data as cConfimration).webaddressformap,
            showcustomerservices:(this.httpResponse.data as cConfimration).showcustomerservices,
            showemail:Boolean((this.httpResponse.data as cConfimration).showemail),
            emailtext:(this.httpResponse.data as cConfimration).emailtext,
            showphone:Boolean((this.httpResponse.data as cConfimration).showphone),
            phonetext:(this.httpResponse.data as cConfimration).phonetext,
            showtandcslink: Boolean((this.httpResponse.data as cConfimration).showtandcslink),
            showendtime: Boolean((this.httpResponse.data as cConfimration).showendtime),
            originatingid: (this.httpResponse.data as cConfimration).confirmationid
          };

            this.http.post<confimration>(bmxurl + "/Confirmations", this.Confirmation).subscribe(response => {
            this.httpResponse = response
            if (this.httpResponse.succeeded) {
              this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Confirmation successfully copied' });
              this.editData(this.httpResponse.data.confirmationid)
            }
            else
            {
              this.messageService.add({ severity: 'error', summary: 'Error Copying Confirmation', detail: this.httpResponse.messages[0] });
            }
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Error Copying Confirmation', detail: error.messages });
          })
      
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting confirmation'});

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting confirmation, error:' + error.messages[0]});

      });
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "confirmations/" + this.VenueID + "/" + element.confirmationid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Confirmations, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Confirmations, error: ' + error.messages});
    })
    });
    
  }

  
  }

