<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Email Template
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round" style="width: 50%; float:left;">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Template Name</label>
                    <input [(ngModel)]="emailtemplate.name" id="classname" type="text" [ngStyle]="{'border-color': (emailtemplateNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!emailtemplateNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Email Template name.</div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Email Subject</label>
                    <input [(ngModel)]="emailtemplate.emailsubject" id="classname" type="text" [ngStyle]="{'border-color': (subjectValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!subjectValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Subject.</div>

                <div class="field mb-4 col-12">
                    <p-editor [(ngModel)]="emailtemplate.message" [style]="{'height':'320px'}"></p-editor>
                </div>
                <div *ngIf="!emailtemplateMessageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Email Template message.</div>


                <!-- <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show free text field (this allows you to add adhoc text when using this template, such as when cancelling events)</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="emailtemplate.showcustomerservices" (onChange)="DoValidCheck()"></p-selectButton>
                </div> -->

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Payment message</label>
                    <p-selectButton  [options]="PaymentMessage" [(ngModel)]="emailtemplate.paymentmessagetype" (onChange)="DoValidCheck()"></p-selectButton>
                </div>
                
                <div *ngIf="emailtemplate.paymentmessagetype != 'NONE'" class="field mb-4 col-12">
                    <textarea pInputText type="text" [(ngModel)]="emailtemplate.paymentmessage" style="width:100%; height: 100px;" [ngStyle]="{'border-color': (paymentmessageValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()"></textarea>
                </div>
                <div *ngIf="!paymentmessageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Payment message.</div>

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show customer services</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="emailtemplate.showcustomerservices" (onChange)="DoValidCheck()"></p-selectButton>
                </div>

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show End Time</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="emailtemplate.showendtime" (onChange)="DoValidCheck()"></p-selectButton>
                </div>

                <div *ngIf="emailtemplate.showcustomerservices" class="surface-card p-4 shadow-2 border-round" style="width:100%">
                    <div class="field mb-5 col-12">
                        <label for="venueclosingstartdate">Show email</label>
                        <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="emailtemplate.showemail"  (onChange)="DoValidCheck()"></p-selectButton>
                    </div>

                    <div *ngIf="emailtemplate.showemail" class="field mb-5 col-12">
                        <input [(ngModel)]="emailtemplate.emailtext" id="classname" type="text" [ngStyle]="{'border-color': (emailtextValid==false) ? '#FF0000':''}" pInputText (keyup)="DoValidCheck()">
                    </div>
                    <div *ngIf="!emailtextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>

                    <div class="field mb-5 col-12">
                        <label for="venueclosingstartdate">Show phone number</label>
                        <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="emailtemplate.showphone"  (onChange)="DoValidCheck()"></p-selectButton>
                    </div>

                    <div *ngIf="emailtemplate.showphone" class="field mb-5 col-12">
                        <input [(ngModel)]="emailtemplate.phonetext" id="classname" type="text" [ngStyle]="{'border-color': (phonetextValid==false) ? '#FF0000':''}" pInputText (keyup)="DoValidCheck()">
                    </div>
                    <div *ngIf="!phonetextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Phone number.</div>

                </div>
                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>

        <div class="surface-card p-4 shadow-2 border-round" style="width: 49%; float:left; margin-left:1%;font-size: 17px;margin-bottom:20px;">
            <span style="margin-bottom:20px;">Selectable Fields (Please drag the field you want into section on the left in the position you would it to be)</span>

            <div>
                <div *ngFor="let c of ContArray" draggable="true" id="{{c.fieldname}}" style="width: 167px;margin-right: 10px;border-radius: 30px;text-align: center;margin-bottom: 5px; background-color: #22C5D7; color:white;padding:10px;margin:5px; float: left;">
                    {{c.fieldname}}
                </div>
            </div>
        </div>

        <div class="surface-card p-4 shadow-2 border-round" style="width: 49%; float:left; margin-left:1%;font-size: 17px;">
            <span style="font-weight:bold;">Preview</span>
            <br />
            <div style="margin:20px;">
                <div [innerHTML]="processMessage(emailtemplate.message)| safe: 'html'"></div>
            </div>

            <hr />

            <div *ngIf="emailtemplate.paymentmessagetype != 'NONE'" style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">Payment message</div>
                <div [innerHTML]="emailtemplate.paymentmessage"></div>
                <div *ngIf="emailtemplate.paymentmessagetype == 'TEXTONLYOB'" style="text-align:right;">
                    <button pButton pRipple type="button" class="p-button p-element p-button-info w-auto mt-3">
                    <span class="pi pi-pound" style="margin-right:8px;"></span>
                    Pay £22.54</button>
                </div>
            </div>

            <div *ngIf="emailtemplate.showcustomerservices"  style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">Customer Services</div>

                <div *ngIf="emailtemplate.showemail" style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Email</div>
                    {{emailtemplate.emailtext}}
                </div>

                <div *ngIf="emailtemplate.showphone" style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Phone</div>
                    {{emailtemplate.phonetext}}
                </div>

                <div  style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Terms & Conditions</div>
                    <a href="#">Click here to view Terms and Conditions</a>
                </div>
                
            </div>

        </div>
    </div>
</div>