<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<p-dialog header="Package Conflict" [(visible)]="showDialog" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
  <div><span class="pi pi-exclamation-triangle" style="font-size: 2.5rem;width: 75px;float: left;padding: 14px;color: #EF4444;; float:left;"></span></div>
  <div style="width:85%; float:left;"><b>This package has been changed by another user, you can choose to refresh the page to see those changes or carry on - if you save your changes you will overwrite the changes that have already been made.</b></div>

<ng-template pTemplate="footer">
    <p-button icon="pi pi-refresh" label="Refresh page" styleClass="p-button p-button-success" (click)="doRefresh()"></p-button>
    &nbsp;
    <p-button icon="pi pi-times" (click)="showDialog=false" label="Continue Editing" styleClass="p-button p-button-danger"></p-button>
</ng-template>

</p-dialog>


<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
  <div>
    <div class="text-900 font-medium text-xl mb-3">
      <h3>Package - {{PackageObj.name}}</h3>
    </div>
    
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
      <span class="pi pi-info-circle" style="margin-right:2px;"></span> Define your package here.  It is made up of 3 sections Package Details, Resources and Admissions all 3 sections must be completed.
    </p>

    <!--<div *ngIf="id=='0'" class="surface-card p-4 shadow-2 border-round" style="margin-bottom: 20px;">    
      <p-steps [model]="items"></p-steps>
    </div>-->
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="grid formgrid p-fluid">
        <p-tabView style="width:100%;" [(activeIndex)]="activeIndex">
          <p-tabPanel header="Package Details" leftIcon="pi pi-box">
            <div class="field mb-4 col-12">
              <label for="packageobjname" class="font-medium">Name</label>
              <div style="clear:both;"></div>
              <input [(ngModel)]="PackageObj.name" [ngStyle]="{'border-color': (packageNameValid==false) ? '#FF0000':''}" id="packageobjname" type="text" pInputText>
            </div>
            <div *ngIf="!packageNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Package Name.</div>

            <div class="field mb-4 col-12">
              <label for="packageobjdescription" class="font-medium">Description</label>
              <p-editor [(ngModel)]="PackageObj.description" [style]="{'height':'320px'}"></p-editor>
            </div>
            <div *ngIf="!packageDescValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Package Description.</div>

            <div style="clear:both;width:100%">&nbsp;</div>

            <div class="field mb-4 col-12">

              <label for="packageobjFile" class="font-medium">Upload a header image for Booking Wizard. The image must be smaller than 100KB</label>
              <div style="clear:both;width:100%">&nbsp;</div>
              <p-fileUpload style="float:left;margin-right:10px;" mode="basic" [auto]="true" name="image" url="{{HTMLbmxurl}}/imageupload/{{VenueID}}/{{Identifier}}" accept="image/*" maxFileSize="100000" (onUpload)="uploadedFile($event)" (onError)="onErrorFileUpload($event)" chooseLabel="Upload File"></p-fileUpload>
              
              <button *ngIf="FileChosen != null && FileChosen != ''" pButton pRipple type="button" style="float: left;margin-bottom: 10px;margin-top: 1px !important;height: 50px;" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeImage()">
                <span style="margin-right:10px;" class="pi pi-times"></span>
                Remove Image
              </button>

              <div style="clear:both;width:100%">&nbsp;</div>

              <div *ngIf="FileChosen != null && FileChosen != ''">
              <p style="margin-top:10px;">This is how your image will look in place.</p>

              <div class="surface-card p-4 shadow-2 border-round packageCard" style="width: 460px;">
                <div id="image">
                    <img src="{{ FileChosen }}" style="width: 460px;margin-top: -23px;margin-left: -23px;margin-right: -23px;">
                </div>
                <div style="font-weight: bold; padding: 20px;font-size:larger;">{{PackageObj.name}}</div>
                <div style="padding-left: 20px; padding-right: 20px" [innerHTML]="">  
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </div>

                <div>
                    <br><br>
                </div>
                <div>
                    <div>
                        <p-button label="Book this Package" icon="pi pi-check" style="width:420px;">
                        </p-button>
                    </div>
                </div>
              </div>
            </div>
            </div>

            <!--<div class="field mb-4 col-12">
              <label for="packageobjmessage" class="font-medium">Package Message</label>
              <p-editor [(ngModel)]="PackageObj.message" [style]="{'height':'320px'}"></p-editor>
            </div>-->

            <div class="field mb-4 col-12">
              <label for="packageRule" class="font-medium">Confirmation</label>
              <p-dropdown [options]="Confirmations" [(ngModel)]="PackageObj.confirmation" [(ngModel)]="PackageObj.confirmation" placeholder="Please select a confirmation" [showClear]="true" optionLabel="name"></p-dropdown>
            </div>

            <div class="field mb-4 col-12">
              <label for="packageRule" class="font-medium">Warning</label>
              <p-dropdown [options]="Warnings" [(ngModel)]="PackageObj.warning" placeholder="Please select a Warning" [showClear]="true" optionLabel="name"></p-dropdown>
            </div>

            <div class="field mb-4 col-12">
              <label for="selectionmode" class="font-medium">Visibility:</label>
              <p-selectButton  id="selectionmode" [options]="stateOptionsVisibility" [(ngModel)]="PackageObj.visibility" ></p-selectButton>
            </div>

            <div class="field mb-4 col-12">
              <label for="selectionmode" class="font-medium">Overall Minimum:</label>
              <p-inputNumber inputId="min" [(ngModel)]="PackageObj.min" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
            </div>

            <div class="field mb-4 col-12">
              <label for="selectionmode" class="font-medium">Overall Maximum:</label>
              <p-inputNumber inputId="max" [(ngModel)]="PackageObj.max" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
            </div>


            <div class="field mb-4 col-12">
              <label for="dpStartTime" class="font-medium">Start Date</label>
              <div style="max-width: 500px;">
                  <p-calendar [(ngModel)]="PackageObj.startDate" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="packagestartdate"></p-calendar>     
                </div>
            </div>

            <div class="field mb-4 col-12">
              <label for="dpEndTime" class="font-medium">End Date</label>
              <div style="max-width: 500px;">
                  <p-calendar [(ngModel)]="PackageObj.endDate" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="packageenddate"></p-calendar>     
              </div>
            </div>

            <div class="field mb-4 col-12">
              <label for="colour" class="font-medium">Colour</label>
              <br />
              <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="PackageObj.colour"></p-colorPicker>
              <input style="width:200px;" [(ngModel)]="PackageObj.colour" id="hexcolour" type="text" pInputText>
            </div>

            <div class="field mb-4 col-12">
              <label for="packageRule" class="font-medium">Public Deposit Policy</label>
              <p-dropdown [options]="DepositPolicies" [(ngModel)]="PackageObj.publicDepositPolicy" placeholder="No Deposit Policy" [showClear]="true" optionLabel="name"></p-dropdown>
            </div>

            <div *ngIf="!publicDepositPolicy" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must have a Public Deposit Policy.</div>

            <div class="field mb-4 col-12">
              <label for="packageRule" class="font-medium">Venue Deposit Policy</label>
              <p-dropdown [options]="DepositPolicies" [(ngModel)]="PackageObj.venueDepositPolicy" placeholder="No Deposit Policy" [showClear]="true" optionLabel="name"></p-dropdown>
            </div>

            <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;margin-bottom: 20px;">
              <div class="field mb-4 col-12">
                <label for="" class="font-medium">Tags</label>
                <br />
                <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="PackageObj.tags" display="chip">
                  <ng-template let-value pTemplate="selectedItems">
                    <span *ngFor="let tag of PackageObj.tags">
                      <p-chip label="{{tag.name}}" styleClass="mr-2"  [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                        <!--<span class="p-chips-token-icon pi pi-cog" style="margin-right:8px"></span>-->
                      </p-chip>
                    </span>
                    <div *ngIf="!PackageObj.tags || PackageObj.tags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
            
            <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
              <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                <p-header>
                  <div style="width:100%">
                      <span class="pi pi-cog" style="margin-right: 10px;"></span>Advanced
                  </div>
              </p-header>

                <div class="field mb-4 col-12">
                  <label for="packageRule" class="font-medium">Package Rule</label>
                  <p-dropdown [options]="packageRules.data" [(ngModel)]="PackageObj.packageRule" [(ngModel)]="PackageObj.packageRule" placeholder="No Package Rule" [showClear]="true" optionLabel="name"></p-dropdown>
                </div>
    

                <div class="field mb-4 col-12">
                  <label for="packageRule" class="font-medium">Booking Questionnaire</label>
                  <p-dropdown [options]="BookingQuestionnaires" [(ngModel)]="PackageObj.questionnaire" placeholder="No Booking Questionnaire" [showClear]="true" optionLabel="name"></p-dropdown>
                </div>

                <div style="clear:both;"></div>
                <div class="field mb-4 col-12">
                  <label for="quantity" class="font-medium">Add an early bird discount:</label>
                  <p-selectButton id="pearlybird" [options]="stateOptions" [(ngModel)]="PackageObj.earlybirddiscount" [style]="{'width': '300px'}" ></p-selectButton>
                </div>
                <div style="clear:both;"></div>
                <div *ngIf="PackageObj.earlybirddiscount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Is this discount to be applied between specific dates or for a number of days before?</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="stateOptionsRangeType" [(ngModel)]="PackageObj.discountrangetype" ></p-selectButton>
                </div>

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Discount type</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="stateOptions3" [(ngModel)]="PackageObj.discounttype" ></p-selectButton>
                </div>

                <div *ngIf="PackageObj.discounttype == 'FIXED'" class="field mb-4 col-12">
                    <label for="eventEarlybirdDiscountAmount" class="font-medium">Discount Amount</label>
                    <p-inputNumber id="eventEarlybirdDiscountAmount" [style]="{'width': '300px'}" mode="currency" currency="{{CurrencySymbol}}" [(ngModel)]="PackageObj.discountamount" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div *ngIf="PackageObj.discounttype == 'PERCENTAGE'" class="field mb-4 col-12">
                    <label for="eventEarlybirdDiscountAmount" class="font-medium">Discount Amount</label>
                    <p-inputNumber id="eventEarlybirdDiscountAmount" [style]="{'width': '300px'}" suffix="%" [(ngModel)]="PackageObj.discountamount" [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div *ngIf="PackageObj.discountrangetype == 0">
                  <div class="field mb-4 col-12">
                      <label for="eventEarlybirdDiscountStartDate" class="font-medium">Discount Start Date</label>
                      <p-calendar id="eventEarlybirdDiscountStartDate" [utc]="true" [style]="{'width': '300px'}" [(ngModel)]="PackageObj.discountstart" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventEarlybirdDiscountStartDate"></p-calendar>
                    </div>

                  <div class="field mb-4 col-12">
                      <label for="eventEarlybirdDiscountEndDate" class="font-medium">Discount End Date</label>
                      <p-calendar id="eventEarlybirdDiscountEndDate" [utc]="true" [style]="{'width': '300px'}" [(ngModel)]="PackageObj.discountend" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventEarlybirdDiscountEndDate"></p-calendar>
                  </div>
                </div>
                <div *ngIf="PackageObj.discountrangetype == 1">
                  <div class="field mb-4 col-12" style="margin-top:20px">
                    <label for="quantity" class="font-medium">Number of days before event the discount is active for</label>
                    <p-inputNumber [(ngModel)]="PackageObj.discountdaysbefore" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="0" max="365"></p-inputNumber>
                  </div>
                </div>
                </div>
                <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
                  <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                    <p-header>
                      <div style="width:100%">
                          <span class="pi pi-calendar" style="margin-right: 10px;"></span>Booking Date Restriction
                      </div>
                  </p-header>

                    <!-- <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left; margin-bottom:20px;"> -->
                      <div class="field mb-4 col-12">
                        <div style="margin-bottom: 10px;">
                          The following settings are copied from the default values in venue settings, However you can change them here by typing in new values.
                        </div>

                        <div class="field mb-4 col-12">
                          <label for="" class="font-medium">Bookings cannot be made within this much time, if the customer chose 3 hours, this would mean if a customer is trying to book for today then they would see time slots in 3 hours time even if you could fit them in sooner.</label>
                          
                          <div style="width:54px; float: left; margin-top:13px;">Days</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesD" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesD" [showButtons]="true" max="365" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                          <div style="width:61px; float: left; margin-top:13px;">Hours</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesH" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesH" [showButtons]="true" max="24" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                          <div style="width:74px; float: left; margin-top:13px;">Minutes</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesM" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesM" [showButtons]="true" max="60" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                        </div>
                        
                        <div class="field mb-4 col-12" style="margin-top:20px;">
                          <label style="margin-top:20px;" for="" class="font-medium">Bookings cannot be made after this much time, if you chose 365 days, would mean if a customer is trying to book, they would not see this package if they pick a date which is more than 365 days from now.</label>
                          <!-- <p-inputNumber id="BookingsCannotBeMadeAfterXMinutes" [style]="{'width': '300px'}" [(ngModel)]="PackageObj.bookingscannotbemadeafterxminutes== null ? 0: PackageObj.bookingscannotbemadeafterxminutes" [showButtons]="true" max="525600" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber> -->
                          <div style="width:54px; float: left; margin-top:13px;">Days</div><div style="float:left; width:190px;"><p-inputNumber id="bookingscannotbemadeafterxDays" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingscannotbemadeafterxDays" [showButtons]="true" max="365" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                        </div>
                        <div style="clear:both;"></div>
                        <div class="field mb-4 col-12" style="margin-top:20px;">
                          <label for="" class="font-medium">Can bookings be made for today? (If 'no' bookings cannot be made for the current day regardless of the time of day)</label><br>
                          <p-selectButton id="CannotMakeBookingsToday" [(ngModel)] = "PackageObj.cannotmakebookingstoday" [style]="{'width': '150px'}" [options]="delayBookingStateOptions" ></p-selectButton>
                        </div>
                      </div>
                    <!-- </div> -->
                    <div style="clear:both;"></div>
                  </p-accordionTab>
                </p-accordion>

                <div style="clear:both;"></div>
              </p-accordionTab>
            </p-accordion>


            <div *ngIf="this.id == '0'">
              <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="NextRes()" style="margin-top: 24px !important; margin-bottom: -25px;"> Next <span class="pi pi-angle-right"></span></button> &nbsp; 
              <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
            </div>

          </p-tabPanel>
          <p-tabPanel header="Resources" leftIcon="pi pi-clone">

            <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
              <div style="float:left; width:32%">
                <div class="drag-column">
                  <h5> Available Resources </h5>
                  <p-table [value]="availableResources">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width:90%">Name</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-resource>
                      <tr>
                        <td *ngIf="!resource.isresourcecontainer"><span class="pi pi-stop" style="margin-right: 10px;"></span>{{resource.name}}</td>
                        <td *ngIf="resource.isresourcecontainer && !resource.isconstructed"><span class="pi pi-clone" style="margin-right: 10px;"></span>{{resource.name}}</td>
                        <td *ngIf="resource.isresourcecontainer && resource.isconstructed"><span class="pi pi-sitemap" style="margin-right: 10px;"></span>{{resource.name}}</td>
                        <td>&nbsp;</td>
                        <td>
                          <button pButton pRipple type="button" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToResources(resource)">
                            <span class="pi pi-angle-right"></span>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <!--<div *ngIf="availableResources.length == 0" style="padding: 15px;">
                    <i>No resources available</i>
                  </div>-->
                </div>
              </div>
              <div style="float:left; width:65%">
                <div class="col-12 md:col-12 drop-column" pDroppable="resources">
                  <h5> Selected Resources </h5>
                  <p-table [value]="PackageObj.resources">
                    <ng-template pTemplate="body" let-resource let-columns="columns" let-index="rowIndex">
                      <tr [pReorderableRow]="index">
                      <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                        
                      </td>
                      <td>
                        <p-accordion style="background-color: white !important; margin-top: 5px; border: none;">
                        <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;">
                          <p-header>
                            <div *ngIf="!resource.isresourcecontainer" style="width:100%">
                                <span class="pi pi-stop" style="margin-right: 10px;"></span>{{resource.name}}&nbsp;(Length:{{resource.length}}&nbsp;{{resource.exclusive? ", Exclusive": ""}})
                            </div>
                    
                            <div *ngIf="resource.isresourcecontainer && !resource.isconstructed" style="width:100%">
                                <span class="pi pi-clone" style="margin-right: 10px;"></span>{{resource.name}}
                            </div>

                            <div *ngIf="resource.isresourcecontainer && resource.isconstructed" style="width:100%">
                              <span class="pi pi-sitemap" style="margin-right: 10px;"></span>{{resource.name}}
                          </div>
                        </p-header>

                          <div class="surface-card p-4 shadow-2 border-round">
                            <div class="grid formgrid p-fluid">

                              <div class="field mb-4 col-12">
                                <label for="selectionmode" class="font-medium">Mode:</label>
                                <p-selectButton id="selectionmode" [options]="stateOptionsComplexSimple" [(ngModel)]="resource.mode" ></p-selectButton>
                              </div>

                                <div class="field mb-4 col-12">
                                  <label for="selectionmode" class="font-medium">Length:</label>
                                  <p-inputNumber inputId="rlen{{index}}" [(ngModel)]="PackageObj.resources[index].length" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
                                </div>
                                <div class="field mb-4 col-12">
                                  <label for="selectionmode" class="font-medium">Exclusivity:</label>
                                  <p-selectButton  [options]="stateOptionsRes" [(ngModel)]="PackageObj.resources[index].exclusive"></p-selectButton>
                                </div>

                                <div *ngIf="resource.mode=='COMPLEX'">

                                  <div *ngIf="resource.isconstructed">
                                    <div class="field mb-4 col-12">
                                      <label for="selectionmode" class="font-medium">Select if the customer selects from a list or any of the available resources or if the system chooses it for them.</label>
                                      <p-selectButton id="selectionmode"  [options]="stateOptionsSel" [(ngModel)]="resource.selectionmode" ></p-selectButton>
                                    </div>
                                    
                                    <div *ngIf="resource.selectionmode == 'LIST' || resource.selectionmode == 'SYSTEMLIST'" class="field mb-4 col-12">
                                      <label for="selectionmode" class="font-medium">Choose Selectable Resourses</label>
                                      <p-table [value]="resource.resources"
                                      [(selection)]="resource.selectableresources">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th>&nbsp;</th>
                                            <th style="width: 40%;">Name</th>
                                            <th style="width: 27%;">&nbsp;</th>
                                            <th style="width: 33%;">&nbsp;</th>
                                          </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-selResources>
                                          <tr>
                                            <td>
                                              <p-tableCheckbox [value]="selResources"></p-tableCheckbox>
                                            </td>
                                            <td>
                                              {{selResources.name}}
                                            </td>
                                            <td>
                                              <!--<p-inputNumber inputId="rlen{{index}}"[(ngModel)]="selResources.length" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>-->
                                            </td>
                                            <td>
                                              <!--<p-inputNumber inputId="rprice{{index}}" [(ngModel)]="selResources.price" [showButtons]="true" mode="currency" currency="{{CurrencySymbol}}" buttonLayout="horizontal"></p-inputNumber>-->
                                            </td>
                                          </tr>
                                        </ng-template>
                                      </p-table>
                                    </div>
                                  </div>

                              <div class="field mb-4 col-12">
                                <label for="ResourceStart" class="font-medium">Is this resource offset? (does it run at the sametime as other resources but may start a number of minutes after the start of the booking)</label>
                                <p-selectButton id="ResourceStart"  [options]="stateOptionsBool" [(ngModel)]="resource.inline" ></p-selectButton>
                              </div>

                              <div *ngIf="!resource.inline">
                                <div class="field mb-4 col-12">
                                  <label for="ResourceStart" class="font-medium">When does this Resource start? ('At Start' will start this resource at the start of the booking, 'At End' will start this resource at the end of the booking and Offset will start the resource a number of minutes either negative or positive after the start of the booking e.g -10 minutes would start the resource 10 minutes before the booking starts.)</label>
                                  <p-selectButton id="ResourceStart"  [options]="stateOptionsStart" [(ngModel)]="resource.startwhen"></p-selectButton>
                                </div>

                                <div *ngIf="resource.startwhen == 'OFFSET'" class="field mb-4 col-12">
                                  <label for="quantity" class="font-medium">Offset in number of minutes</label>
                                  <p-inputNumber id="quantity" [(ngModel)]="resource.numofminutes== null ? 1: resource.numofminutes" [showButtons]="true" max="420" min="-420" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                                </div>

                                <div *ngIf="resource.startwhen != 'OFFSET'" class="field mb-4 col-12">
                                  <label style="display:none;" for="quantity" class="font-medium">Offset in number of minutes</label>
                                  <p-inputNumber style="display:none;" id="quantity" [(ngModel)]="resource.numofminutes== null ? 1: resource.numofminutes" [showButtons]="true" max="420" min="-420" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>                              
                                </div>
                              </div>

                              <div class="field mb-4 col-12">
                                <label for="ResourceOpt" class="font-medium">Is this Resource optional?</label>
                                <p-selectButton id="ResourceOpt"  [options]="stateOptions" [(ngModel)]="resource.optional" ></p-selectButton>
                              </div>

                              <div class="field mb-4 col-12">
                                <label for="QuantityMode" class="font-medium">Will this resource be for the whole group, just those participating or should the customer choose</label>
                                <p-selectButton id="QuantityMode"  [options]="stateOptionsQty" [(ngModel)]="resource.quantityselector" ></p-selectButton>
                              </div>

                              <!--<div *ngIf="resource.quantityselector !='GROUPSIZE' && resource.quantityselector !='PARTICIPANT'" class="field mb-4 col-12">
                                <label for="quantity" class="font-medium">Quantity</label>
                                <p-inputNumber id="quantity" [(ngModel)]="resource.quantity== null ? 1: resource.quantity" [showButtons]="true" max="999" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                              </div>-->

                              <div style="display:none">
                                <div class="field mb-4 col-12">
                                  <label for="quantity" class="font-medium">Price:</label>
                                  <p-inputNumber  [(ngModel)]="resource.price" [showButtons]="true" buttonLayout="horizontal" mode="currency" currency="{{CurrencySymbol}}"></p-inputNumber>
                                </div>

                                <div class="field mb-4 col-12">
                                  <label for="QuantityMode" class="font-medium">Is this price fixed or per person?</label>
                                  <p-selectButton id="PriceMode"  [options]="stateOptionsPrice" [(ngModel)]="resource.fixedprice" ></p-selectButton>
                                </div>
                              </div>
                              </div>
                              
                            </div>
                          </div>
                        </p-accordionTab>
                      </p-accordion>
                      </td>
                      <td>
                        
                        <button pButton pRipple type="button" [style]="{'margin-bottom': '10px'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromResources(resource)">
                          <span class="pi pi-angle-left"></span>
                        </button>
                      </td>
                    </tr>
                    </ng-template>
                  </p-table>

                  <div class="surface-card p-4 shadow-2 border-round">
                    <div>
                    <b>Example Itinerary</b>
                    <br />
                      {{itinerary}}<br>
                        <span *ngFor="let item of discrepantitinerary">{{item}}</span>
                      <div>
                        <button pButton pRipple type="button" [style]="{'margin-bottom': '10px'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="buildItinerary()">
                          <span style="margin-right:10px;" class="pi pi-refresh"></span>
                          Refresh Itinerary
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="this.id == '0'">
              <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="NextAds()" style="margin-top: 24px !important; margin-bottom: -25px;"> Next <span class="pi pi-angle-right"></span></button> &nbsp; 
              <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
            </div>

          </p-tabPanel>
          <p-tabPanel header="Admissions" leftIcon="pi pi-users">
            <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
              <div style="float:left; width:32%">
                <div class="col-12 md:col-12 drag-column">
                  <h5> Available Admissions </h5>
                  <p-table [value]="availableAdmissions">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 90%;">Name</th>
                        <th>&nbsp;</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-admission>
                      <tr>
                        <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{admission.name}}</td>
                        <td>
                          <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToAdmissions(admission)">
                            <span class="pi pi-angle-right"></span>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <div *ngIf="availableAdmissions.length == 0" style="padding: 15px;">
                    <i>No admissions available</i>
                  </div>
                </div>
              </div>
              <div style="float:left; width:65%">
                <div class="col-12 md:col-12 drop-column" pDroppable="admissions">
                  <h5> Selected Admissions </h5>
                  <p-table [value]="PackageObj.admissions">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-admission let-columns="columns" let-index="rowIndex">
                      <tr [pReorderableRow]="index">
                        <td>
                          <span class="pi pi-bars" pReorderableRowHandle></span>
                        </td>
                        <td>
                          <p-accordion style="background-color: white !important; margin-top: 5px; border: none;">
                            <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;">
                              <p-header>
                                <div style="width:100%">
                                    <span class="pi pi-user" style="margin-right: 10px;"></span>{{admission.name}} &nbsp;(Min:&nbsp;{{admission.min}},&nbsp;Max:&nbsp;{{admission.max}},&nbsp;Price:&nbsp;{{admission.price | currency:this.CurrencySymbol}})
                                </div>
                            </p-header>
    
                              <div class="surface-card p-4 shadow-2 border-round">
                                <div class="grid formgrid p-fluid">
    
                                  <div class="field mb-4 col-12">
                                    <label for="ResourceStart" class="font-medium">Booking Wizard Text</label>
                                    <input [(ngModel)]="admission.bookingwizardtext" id="classname" type="text" pInputText>
                                  </div>
    
                                  <div class="field mb-4 col-12">
                                    <label for="quantity" class="font-medium">Minumum</label>
                                    <p-inputNumber [(ngModel)]="admission.min" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                  </div>
    
                                  <div class="field mb-4 col-12">
                                    <label for="ResourceOpt" class="font-medium">Maximum</label>
                                    <p-inputNumber [(ngModel)]="admission.max" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                  </div>
    
                                  <div class="field mb-4 col-12">
                                    <label for="quantity" class="font-medium">Price:</label>
                                    <p-inputNumber  [(ngModel)]="admission.price" [showButtons]="true" buttonLayout="horizontal" mode="currency" currency="{{CurrencySymbol}}"></p-inputNumber>
                                  </div>

                                  <!--advanced-->
                                  <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
                                    <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                                      <p-header>
                                        <div style="width:100%">
                                            <span class="pi pi-cog" style="margin-right: 10px;"></span>Advanced
                                        </div>
                                    </p-header>

                                    <div class="field mb-4 col-12">
                                      <label for="QuantityMode" class="font-medium">Must Include:</label>
                                      <p-selectButton id="pmi"  [options]="stateOptionsMI" [(ngModel)]="admission.mustinclude" ></p-selectButton>
                                    </div>
                                    
                                    <div class="field mb-4 col-12">
                                      <label for="QuantityMode" class="font-medium">Class:</label>
                                      <p-dropdown [options]="classes" [(ngModel)]="admission.admissionClass" placeholder="No Class" [showClear]="true" optionLabel="name"></p-dropdown>
                                    </div>

                                    <div class="field mb-4 col-12">
                                        <label for="quantity" class="font-medium">Participant:</label>
                                        <p-selectButton id="ppar" [options]="stateOptionsPart" [(ngModel)]="admission.participant" ></p-selectButton>
                                    </div>

                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Fixed Price:</label>
                                      <p-selectButton id="ppp" [options]="stateOptionsPPP" [(ngModel)]="admission.fixedPrice" ></p-selectButton>
                                    </div>

                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Do you want to link any admissions?:</label>
                                      <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.uselinkedadmissions" ></p-selectButton>
                                    </div>

                                    <div *ngIf="admission.uselinkedadmissions" style="margin-bottom:20px;" class="surface-card p-4 shadow-2 border-round">
                                          
                                      <div  *ngIf="admission.uselinkedadmissions"  class="field mb-4 col-12" style="margin-top:20px;">
                                        <label for="quantity" class="font-medium">Would you like to only set the maximum of this admission based on the linked admissions:</label>
                                        <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.onlysetmax" ></p-selectButton>
                                      </div>

                                      <div style="width:100%;">
                                        <div style="float:left; width:87%;">
                                          <p-dropdown [options]="PackageObj.admissions" [(ngModel)]="CurrentSelectedLinkedAdmission" placeholder="Please select as Admission" [showClear]="true" optionLabel="name"></p-dropdown>
                                        </div>
                                        <div style="float:left; width:3%;">
                                          &nbsp;
                                        </div>
                                        <div style="float:left; width:10%;">
                                          <button pButton pRipple type="button" style="margin-bottom: 10px;margin-top: 0px !important;height: 50px;" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="addAdmissionToLinked(admission)">
                                            Add
                                          </button>
                                        </div>
                                      </div>

                                      <div style="clear:both;"></div>

                                      <div>
                                        <p-table [value]="admission.linkedadmissions">
                                          <ng-template pTemplate="header">
                                            <tr>
                                              <th style="width: 95%;">Name</th>
                                              <!-- <th style="width: 20%;">Threshold</th> -->
                                              <th style="width: 5%;">&nbsp;</th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-linkedadmissions>
                                            <tr>
                                              <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{linkedadmissions.admissionname}}</td>
                                              <!-- <td>
                                                <p-inputNumber min="1" max="10000"  [(ngModel)]="linkedadmissions.threshold" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                              </td> -->
                                              <td>
                                                <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeLinkedAdmission(linkedadmissions)">
                                                  <span class="pi pi-trash"></span>
                                                </button>
                                              </td>
                                            </tr>
                                          </ng-template>
                                        </p-table>
                                      </div>
                                    </div>

                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Do you want to link any admissions for a discount?:</label>
                                      <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.uselinkeddiscounts" ></p-selectButton>
                                    </div>

                                    <div *ngIf="admission.uselinkeddiscounts" class="surface-card p-4 shadow-2 border-round">
                                      <div *ngIf="admission.uselinkeddiscounts" style="width:100%;">
                                        <div style="float:left; width:87%;">
                                          <p-dropdown [options]="PackageObj.admissions" [(ngModel)]="CurrentSelectedAdmission" placeholder="Please select as Admission" [showClear]="true" optionLabel="name"></p-dropdown>
                                        </div>
                                        <div style="float:left; width:3%;">
                                          &nbsp;
                                        </div>
                                        <div style="float:left; width:10%;">
                                          <button pButton pRipple type="button" style="margin-bottom: 10px;margin-top: 0px !important;height: 50px;" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="addAdmissionToLinkedDiscount(admission)">
                                            Add
                                          </button>
                                        </div>
                                      </div>

                                      <div style="clear:both;"></div>

                                      <div *ngIf="admission.uselinkeddiscounts" >
                                        <p-table [value]="admission.linkeddiscounts">
                                          <ng-template pTemplate="header">
                                            <tr>
                                              <th style="width: 55%;">Name</th>
                                              <th style="width: 2%;">Discount</th>
                                              <th style="width: 20%;">Threshold</th>
                                              <th style="width: 5%;">&nbsp;</th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-linkeddiscounts>
                                            <tr>
                                              <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{linkeddiscounts.admissionname}}</td>
                                              <td>
                                                <p-inputNumber min="1" max="100" [(ngModel)]="linkeddiscounts.percentagediscount" suffix="%" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                              </td>
                                              <td>
                                                <p-inputNumber min="1" max="10000"  [(ngModel)]="linkeddiscounts.threshold" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                              </td>
                                              <td>
                                                <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeLinkedDiscountAdmission(linkeddiscounts)">
                                                  <span class="pi pi-trash"></span>
                                                </button>
                                              </td>
                                            </tr>
                                          </ng-template>
                                        </p-table>

                                        <!-- <div class="field mb-4 col-12" style="margin-top:20px;">
                                          <label for="quantity" class="font-medium">Maximum number of linked discounts:</label>
                                          <p-inputNumber  [(ngModel)]="admission.maxlinkeddiscounts" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="1" max="100"></p-inputNumber>
                                        </div> -->
                                      </div>
                                    </div>

                                  </p-accordionTab>
                                </p-accordion>
                                </div>
                              </div>
                            </p-accordionTab>
                          </p-accordion>
                        </td>
                        <td>
                          <button pButton pRipple type="button" [style]="{'margin-bottom': '10px'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromAdmissions(admission)">
                            <span class="pi pi-angle-left"></span>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <!--<div *ngIf="selectedAdmissions.length == 0" style="padding: 15px;">
                    <i>No admissions selected, drag some here.</i>
                  </div>-->
                </div>
              </div>
            </div>

            <div *ngIf="this.id == '0'">
              <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData()" style="margin-top: 24px !important; margin-bottom: -25px;"> Save Changes <span class="pi pi-angle-right"></span></button> &nbsp; 
              <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
            </div>

          </p-tabPanel> 
          </p-tabView>
      </div>
    </div>
    <div class="col-12" *ngIf="this.id != '0'">
      <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData()"><span class="pi pi-pencil" style="margin-right:8px;"></span> Save Changes </button> &nbsp; 
      <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
    </div>
  </div>
  <style>
    :host ::ng-deep .p-chip.custom-chip {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }
  </style>