import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess, ConvertDateFromDB, ConvertDateToDB} from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { packageObj, cPackage } from 'src/types/package';
import { cResource, resource } from 'src/types/resource';
import { cAdmission } from 'src/types/admission';
import { tag_returndata } from 'src/types/tag_returndata';
import { resourceType_returndata } from 'src/types/resource_returndata';
import { admission_returndata } from 'src/types/admission_returndata';
import { v4 as uuidv4  } from 'uuid';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { restriction_returndata } from 'src/types/restriction_returndata';
import { depositpolicy_returndata } from 'src/types/depositpolicy_returndata';

import { resourcegroupname_returndata } from 'src/types/resourcegroupname_returndata';
import { resourcegroupname } from 'src/types/resourcegroupname';
import { cClass } from 'src/types/class';
import { class_returndata } from 'src/types/class_returndata';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { confirmation_returndata } from 'src/types/confirmation_returndata';
import { environment } from 'src/environments/environment';
import { users_returndata } from 'src/types/user_returndata';
import { link } from 'fs';
import { warnings_returndata } from 'src/types/warnings_returndata';


@Component({
    selector: 'app-packageedit',
    templateUrl: './packageedit.component.html',
    styleUrls: ['./packageedit.component.css']
})

export class PackageeditComponent implements OnInit {
    VenueID: string = localStorage.getItem("SmartVenueID")!;
    PackageObj: cPackage;
    httpResponse: any;
    id: string;
    stateOptionsRes: any;
    stateOptionsPPP: any;
    stateOptionsMI: any;
    stateOptionsPart: any;
    stateOptions: any;
    stateOptionsComplexSimple: any;

    packageLen: number = 0;
    availableResources: any;
    selectedResources: any = [];
    stateOptionsSelMode: any;
    stateOptionsSel: any;
    stateOptionsQty: any;
    stateOptionsQty2: any;
    stateOptionsStart: any;
    stateOptionsBool: any;
    stateOptionsVisibility: any;
    stateOptionsPrice: any;

    availableAdmissions: any = [];
    selectedAdmissions: any = [];
    draggedAdmission: any;
    DepositPolicies: any;

    builtResources: any;
    builtAdmissions: any;

    packageRules: any = [];
    packageRestriction: any = [];
    classes: cClass[];

    tags: any;
    items: any;
    activeIndex: number;

    packageNameValid: boolean = true;
    packageDescValid: boolean = true;

    itinerary: string = "";
    discrepantitinerary: string[] = [];

    availableResourceGroups: resourcegroupname[] = [];
    selectedItems: any;

    showDialog: boolean;

    hubConnection: HubConnection;

    HTMLbmxurl: string = bmxurl;
    Identifier: string = uuidv4();
    FileChosen: string = "";

    Confirmations: any[];
    Warnings: any[];

    SortedItinerary: any[];

    AccessAllowed: boolean = false;
    CompletedLoading: boolean = false;

    BookingQuestionnaires: any[] = [];
    CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

    delayBookingStateOptions: any[] = [];

    stateOptionsRangeType: any[]
    stateOptions3: any[];

    bookingCannotBeMadeWithinXMinutesD: number = 0;
    bookingCannotBeMadeWithinXMinutesH: number = 0;
    bookingCannotBeMadeWithinXMinutesM: number = 0;

    bookingscannotbemadeafterxDays: number = 0;

    LinkedAdmissions: boolean = false;

    CurrentSelectedAdmission: cAdmission;
    CurrentSelectedLinkedAdmission: cAdmission;

    publicDepositPolicy: boolean = true;


    constructor(private route: ActivatedRoute, 
      private http: HttpClient, 
      private router: Router, 
      private messageService: MessageService, 
      private auth: AuthService) {
      this.stateOptionsRes = [{ label: 'Shared', value: false }, { label: 'Exclusive', value: true }];
      this.stateOptionsPPP = [{ label: 'Per Booking', value: true }, { label: 'Per Person', value: false }];
      this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsMI = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsPart = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsPrice = [{ label: 'Fixed Price', value: true }, { label: 'Per Person', value: false }];
      this.stateOptionsBool = [{ label: 'Yes', value: false }, { label: 'No', value: true }];
      this.stateOptionsVisibility = [{ label: 'Hidden', value: 'NEITHER' }, { label: 'Public Bookings Only', value: 'PUBLIC' }, { label: 'Preview/Venue Only', value: 'VENUE' }, { label: 'Public and Venue Bookings', value: 'BOTH' }];

      this.stateOptionsRangeType = [{ label: 'Date Range', value: 0 }, { label: 'Days before', value: 1 }];

      this.stateOptionsSel =  [{ label: 'Customer chooses from any', value: "ANY" }, { label: 'Customer chooses from list', value: "LIST" }, { label: 'System chooses from any', value: "SYSTEMANY" }, { label: 'System chooses from list', value: "SYSTEMLIST" }];
      this.stateOptionsQty =  [{ label: 'Group Size', value: "GROUPSIZE" }, { label: 'Participant', value: "PARTICIPANT" }, { label: 'Customer Chooses', value: "CUSTOM" }];
      
      this.stateOptionsQty2 =  [{ label: 'Group Size', value: "GROUPSIZE" }, { label: 'Participant', value: "PARTICIPANT" }];
      
      this.stateOptionsStart = [{ label: 'At Start', value: "ATSTART"}, { label: 'At End', value: "ATEND"}, { label: 'Offset', value: "OFFSET"}];
      this.stateOptionsComplexSimple = [{ label: 'Simple', value: 'SIMPLE' }, { label: 'Complex', value: "COMPLEX"}];

      this.delayBookingStateOptions = [{label: 'No', value: false}, {label: 'Yes', value: true}];

      this.stateOptions3 = [{ label: 'Fixed Amount', value: "FIXED" }, { label: 'Percentage', value: "PERCENTAGE" }];

      this.loadConfirmations(false);
      this.loadWwarnings(false);
      this.loadResources(false);
      this.loadAdmissions(false);
      this.loadTags(false);
      this.loadRestrictions(false);
      this.loadPackageRules(false);
      this.loadDepositPolidies(false);
      this.loadClasses(false);
      this.loadQuestionnaires();

      this.PackageObj = {
        id: null,
        packageId: null,
        name: "",
        eventid: null,
        description: "",
        imageurl: "",
        message: "",
        disabled: false,
        venueID: this.VenueID,
        resources: [],
        startTimes: null,
        admissions: [],
        packageLength: 0,
        questionnaire: null,
        visibility: "VENUE",
        min:0,
        max:0,
        tags: null,
        startDate: new Date(),
        endDate:  this.add_years(new Date(), 5),
        colour: "#990000",
        packageRule: null,
        packageRestriction: null,
        extras: null,
        publicDepositPolicy: null,
        venueDepositPolicy: null,
        additionalresources: null,
        confirmation: null,
        warning: null,
        originatingid:  "",
        bookingscannotbemadewithinxminutes: localStorage.getItem("BookingsCannotBeMadeWithinXMinutes") == null ? 0 : parseInt(localStorage.getItem("BookingsCannotBeMadeWithinXMinutes")!),
        bookingscannotbemadeafterxminutes: localStorage.getItem("BookingsCannotBeMadeAfterXMinutes") == null ? 0 : parseInt(localStorage.getItem("BookingsCannotBeMadeAfterXMinutes")!),
        cannotmakebookingstoday: localStorage.getItem("CannotMakeBookingsToday") == null ? false : localStorage.getItem("CannotMakeBookingsToday") == "true" ? true : false,
        discounttype: "PERCENTAGE",
        discountamount: 0,
        discountcode: null,
        discountstart: new Date(),
        discountend: this.addYears(new Date(), 5),
        earlybirddiscount: false,
        discountrangetype: 0,
        discountdaysbefore: 0,
        imageguid: this.Identifier
    };

    if(this.PackageObj.imageguid == null || this.PackageObj.imageguid == "")
    {
      this.Identifier = uuidv4();
      this.PackageObj.imageguid = this.Identifier;
    }

    }

    removeLinkedDiscountAdmission(adm)
    {
      this.PackageObj.admissions.forEach(element => {
        if(element.linkeddiscounts != null)
        {
          element.linkeddiscounts = element.linkeddiscounts.filter(obj => obj.admissionid !== adm.admissionid);
        }
      });
    }

    addAdmissionToLinkedDiscount(adm){  
      if(this.CurrentSelectedAdmission != null)
      {
        if (adm.linkeddiscounts == null)
          adm.linkeddiscounts = [];

        let found: boolean = false;
        adm.linkeddiscounts.forEach(element => {
          if(element.admissionid == this.CurrentSelectedAdmission.admissionid)
            found = true;
        });

        if(!found)
        {
          let newLinkedAdm: any = {
            admissionname: this.CurrentSelectedAdmission.name,
            threshold: 1,
            percentagediscount: 100,
            admissionid: this.CurrentSelectedAdmission.admissionid
          }
          adm.linkeddiscounts.push(newLinkedAdm);
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Admission already linked'});
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'You must select an admission to link to'});
      }
    }

    
    removeLinkedAdmission(adm)
    {
      this.PackageObj.admissions.forEach(element => {
        if(element.linkedadmissions != null)
        {
          element.linkedadmissions = element.linkedadmissions.filter(obj => obj.admissionid !== adm.admissionid);
        }
      });
    }

    addAdmissionToLinked(adm){  
      if(this.CurrentSelectedLinkedAdmission != null)
      {
        if (adm.linkedadmissions == null)
          adm.linkedadmissions = [];

        let found: boolean = false;
        adm.linkedadmissions.forEach(element => {
          if(element.admissionid == this.CurrentSelectedLinkedAdmission.admissionid)
            found = true;
        });

        if(!found)
        {
          let newLinkedAdm: any = {
            admissionname: this.CurrentSelectedLinkedAdmission.name,
            threshold: 1,
            percentagediscount: 100,
            admissionid: this.CurrentSelectedLinkedAdmission.admissionid
          }
          adm.linkedadmissions.push(newLinkedAdm);
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Admission already linked'});
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'You must select an admission to link to'});
      }
    }

    add_years(dt,n) 
    {
    return new Date(dt.setFullYear(dt.getFullYear() + n));      
    }

    addYears(date: Date, years: number): Date {
      date.setFullYear(date.getFullYear() + years);
      return date;
    }

    uploadedFile(event){
      this.PackageObj.imageurl = event.originalEvent.body.data;
      let newNonce = uuidv4();

      this.FileChosen = event.originalEvent.body.data + "?n=" + newNonce;
    }

    onErrorFileUpload(event){
      this.messageService.add({ severity: 'error', summary: 'Image not uploaded', detail: 'Could not upload image: ' + event.error });
    }

    doRefresh(){
      if(this.id != "0")
      {
        this.http.get(bmxurl + "package/" + this.VenueID + "/" + this.id).subscribe(response => {
          this.httpResponse = response;
          this.PackageObj = {
            id: (this.httpResponse.data as cPackage).id,
            packageId: (this.httpResponse.data as cPackage).packageId,
            name: (this.httpResponse.data as cPackage).name,
            eventid: (this.httpResponse.data as cPackage).eventid,
            description: (this.httpResponse.data as cPackage).description,
            imageurl: (this.httpResponse.data as cPackage).imageurl,
            message: (this.httpResponse.data as cPackage).message,
            disabled: (this.httpResponse.data as cPackage).disabled,
            venueID: (this.httpResponse.data as cPackage).venueID,
            resources: (this.httpResponse.data as cPackage).resources,
            startTimes: (this.httpResponse.data as cPackage).startTimes,
            admissions: (this.httpResponse.data as cPackage).admissions,
            packageLength: (this.httpResponse.data as cPackage).packageLength,
            questionnaire: (this.httpResponse.data as cPackage).questionnaire,
            min: (this.httpResponse.data as cPackage).min,
            max: (this.httpResponse.data as cPackage).max,
            tags: (this.httpResponse.data as cPackage).tags,

            startDate: ConvertDateFromDB((this.httpResponse.data as cPackage).startDate),
            endDate: ConvertDateFromDB((this.httpResponse.data as cPackage).endDate),

            visibility: (this.httpResponse.data as cPackage).visibility,
            colour: (this.httpResponse.data as cPackage).colour,
            packageRule: (this.httpResponse.data as cPackage).packageRule,
            packageRestriction: (this.httpResponse.data as cPackage).packageRestriction,
            extras: (this.httpResponse.data as cPackage).extras,
            publicDepositPolicy: (this.httpResponse.data as cPackage).publicDepositPolicy,
            venueDepositPolicy: (this.httpResponse.data as cPackage).venueDepositPolicy,
            additionalresources:  (this.httpResponse.data as cPackage).additionalresources,
            confirmation: (this.httpResponse.data as cPackage).confirmation,
            warning: (this.httpResponse.data as cPackage).warning,
            originatingid: (this.httpResponse.data as cPackage).originatingid,
            bookingscannotbemadewithinxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadewithinxminutes,
            bookingscannotbemadeafterxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadeafterxminutes,
            cannotmakebookingstoday: (this.httpResponse.data as cPackage).cannotmakebookingstoday,
            discounttype: (this.httpResponse.data as cPackage).discounttype,
            discountamount: (this.httpResponse.data as cPackage).discountamount,
            discountcode: (this.httpResponse.data as cPackage).discountcode,

            discountstart: ConvertDateFromDB((this.httpResponse.data as cPackage).discountstart),
            discountend: ConvertDateFromDB((this.httpResponse.data as cPackage).discountend),

            earlybirddiscount: (this.httpResponse.data as cPackage).earlybirddiscount,
            discountrangetype: (this.httpResponse.data as cPackage).discountrangetype,
            discountdaysbefore: (this.httpResponse.data as cPackage).discountdaysbefore,
            imageguid: (this.httpResponse.data as cPackage).imageguid
          }
          this.buildItinerary();
          this.showDialog = false;

        }, error => {

        });
      }
    }

    ngOnInit(): void {

      let userid = localStorage.getItem("SmartUserID");

      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("PACKAGE", user)){
            this.AccessAllowed = true;

            
          this.id = this.route.snapshot.paramMap.get('id');
    
          this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
            this.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
            accessTokenFactory: () => tokenReturned 
          }).build();
      
          this.hubConnection.start();
      
          this.hubConnection.on("packageEvent", data => {
            if(data.packageId == this.id && data.packageId != '0') 
              this.showDialog = true;
          });
          
          });
    
          this.items = [
            {label: 'Package Details'},
            {label: 'Resources'},
            {label: 'Admissions'}
          ];
    
          if(this.id != "0")
          {
            this.http.get(bmxurl + "package/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = response;

              this.Identifier = (this.httpResponse.data as cPackage).imageguid;

              this.PackageObj = {
                id: (this.httpResponse.data as cPackage).id,
                packageId: (this.httpResponse.data as cPackage).packageId,
                name: (this.httpResponse.data as cPackage).name,
                eventid: (this.httpResponse.data as cPackage).eventid,
                description: (this.httpResponse.data as cPackage).description,
                imageurl: (this.httpResponse.data as cPackage).imageurl,
                message: (this.httpResponse.data as cPackage).message,
                disabled: (this.httpResponse.data as cPackage).disabled,
                venueID: (this.httpResponse.data as cPackage).venueID,
                resources: (this.httpResponse.data as cPackage).resources,
                startTimes: (this.httpResponse.data as cPackage).startTimes,
                admissions: (this.httpResponse.data as cPackage).admissions,
                packageLength: (this.httpResponse.data as cPackage).packageLength,
                questionnaire: (this.httpResponse.data as cPackage).questionnaire,
                min: (this.httpResponse.data as cPackage).min,
                max: (this.httpResponse.data as cPackage).max,
                tags: (this.httpResponse.data as cPackage).tags,

                //startDate: ConvertDateFromDB((this.httpResponse.data as cPackage).startDate),
                //endDate: ConvertDateFromDB((this.httpResponse.data as cPackage).endDate),

                startDate:  new Date((this.httpResponse.data as cPackage).startDate),
                endDate:  new Date((this.httpResponse.data as cPackage).endDate),

                visibility: (this.httpResponse.data as cPackage).visibility,
                colour: (this.httpResponse.data as cPackage).colour,
                packageRule: (this.httpResponse.data as cPackage).packageRule,
                packageRestriction: (this.httpResponse.data as cPackage).packageRestriction,
                extras: (this.httpResponse.data as cPackage).extras,
                publicDepositPolicy: (this.httpResponse.data as cPackage).publicDepositPolicy,
                venueDepositPolicy: (this.httpResponse.data as cPackage).venueDepositPolicy,
                additionalresources: (this.httpResponse.data as cPackage).additionalresources,
                confirmation: (this.httpResponse.data as cPackage).confirmation,
                warning: (this.httpResponse.data as cPackage).warning,
                originatingid:  (this.httpResponse.data as cPackage).originatingid,
                bookingscannotbemadewithinxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadewithinxminutes,
                bookingscannotbemadeafterxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadeafterxminutes,
                cannotmakebookingstoday: (this.httpResponse.data as cPackage).cannotmakebookingstoday,
                discounttype: (this.httpResponse.data as cPackage).discounttype,

                discountamount: (this.httpResponse.data as cPackage).discountamount,
                discountcode: (this.httpResponse.data as cPackage).discountcode,

                //discountstart: ConvertDateFromDB((this.httpResponse.data as cPackage).discountstart),
                //discountend: ConvertDateFromDB((this.httpResponse.data as cPackage).discountend),

                discountstart: new Date((this.httpResponse.data as cPackage).discountstart),
                discountend: new Date((this.httpResponse.data as cPackage).discountend),

                earlybirddiscount: (this.httpResponse.data as cPackage).earlybirddiscount,
                discountrangetype: (this.httpResponse.data as cPackage).discountrangetype,
                discountdaysbefore: (this.httpResponse.data as cPackage).discountdaysbefore,
                imageguid : (this.httpResponse.data as cPackage).imageguid
              }
              
             this.PackageObj.admissions.forEach(adm => {
                if(adm.linkedadmissions != null)
                {
                  adm.uselinkedadmissions = true;
                }
                else
                {
                  adm.uselinkedadmissions = false;
                }

                if(adm.linkeddiscounts != null)
                  {
                    adm.uselinkeddiscounts = true;
                  }
                  else
                  {
                    adm.uselinkeddiscounts = false;
                  }
              });


              this.Identifier = this.PackageObj.imageguid;

              if(this.PackageObj.imageguid == null || this.PackageObj.imageguid == "")
              {
                this.Identifier = uuidv4();
                this.PackageObj.imageguid = this.Identifier;
              }
              
              this.bookingCannotBeMadeWithinXMinutesD = Math.floor(this.PackageObj.bookingscannotbemadewithinxminutes / 1440);
              this.bookingCannotBeMadeWithinXMinutesH = Math.floor((this.PackageObj.bookingscannotbemadewithinxminutes % 1440) / 60);
              this.bookingCannotBeMadeWithinXMinutesM = this.PackageObj.bookingscannotbemadewithinxminutes % 60;
              
              this.bookingscannotbemadeafterxDays = this.PackageObj.bookingscannotbemadeafterxminutes / 1440;

              this.FileChosen = this.PackageObj.imageurl;
              this.buildItinerary();
    
            }, error => {
            });
          }
          }
  
          this.CompletedLoading = true;

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });


    }

    loadQuestionnaires(){
      this.http.get(bmxurl + "QuestionnaireList/" + this.VenueID).subscribe(response => {
        this.BookingQuestionnaires = (response as any).data;
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Questionnaires, error:' + error.messages[0]});
      });
    }

    genRandomNum()
    {
      return Math.floor((Math.random()*10000)+1).toString();
    }

    loadConfirmations(showdisabled: boolean) {
      this.http.get(bmxurl + "ConfirmationList/" + this.VenueID).subscribe(response => {
        this.Confirmations = (response as confirmation_returndata).data;
      }, error => {
      });
    }

    loadWwarnings(showdisabled: boolean) {
      this.http.get(bmxurl + "WarningList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.Warnings = (response as warnings_returndata).data;
      }, error => {
      });
    }

    loadClasses(showdisabled: boolean) {
      this.http.get(bmxurl + "ClassList/" + this.VenueID).subscribe(response => {
        this.classes = (response as class_returndata).data;
      }, error => {
      });
    }

    loadRestrictions(showdisabled: boolean) {
      this.http.get(bmxurl + "RestrictionList/" + this.VenueID).subscribe(response => {
        this.packageRestriction = (response as restriction_returndata);
      }, error => {
      });
    }

    loadResourceGroups(showdisabled: boolean) {
      this.http.get(bmxurl + "ResourceGroupNameList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableResourceGroups = (response as resourcegroupname_returndata).data;
      }, error => {
      });
    }

    loadPackageRules(showdisabled: boolean) {
      this.http.get(bmxurl + "PackageTypeList/" + this.VenueID).subscribe(response => {
        this.packageRules = (response as packagerule_returndata);
      }, error => {
      });
    }

    loadResources(showdisabled: boolean) {
      this.http.get(bmxurl + "ResourceListForPackage/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableResources = (response as resourceType_returndata).data;
      }, error => {
      });
    }

    loadTags(showdisabled: boolean) {
      this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.tags = (response as tag_returndata).data;
        console.log(this.tags);
      }, error => {
      });
    }

    loadAdmissions(showdisabled: boolean) {
      this.http.get(bmxurl + "AdmissionList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableAdmissions = (response as admission_returndata).data;
      }, error => {
      });
    }

    loadDepositPolidies(showdisabled: boolean) {
      this.http.get(bmxurl + "DepositPolicyList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.DepositPolicies = (response as depositpolicy_returndata).data;
      }, error => {
      });
    }

    returnToList() {
      this.router.navigateByUrl("/package");
    }
    
    AddToResources(resource){
      if(this.PackageObj.resources == null)
        this.PackageObj.resources = [];

        if(resource.isresourcecontainer)
        {
          let newRes: cResource = {
            groupname: "",
            id: uuidv4(),
            resourceId:resource.resourceId,
            name:resource.name,
            useClasses:resource.useClasses,
            exclusive:resource.exclusive,
            eventid: resource.eventid,
            concurrentUses:resource.concurrentUses,
            maxConcurrent:resource.maxConcurrent,
            disabled:resource.disabled,
            venueId:resource.venueId,
            colour:resource.colour,
            orderindex:resource.orderindex,
            length:resource.length,
            classes:resource.classes,
            showonbooking:resource.showonbooking,
            resources:resource.resources,
            isresourcecontainer:resource.isresourcecontainer,
            isconstructed: resource.isconstructed,
            price:resource.price,
            fixedprice:resource.fixedprice,
            resourcegroupname: resource.resourcegroupname,
            tags: resource.tags,
            resourcegroup: resource.resourcegroup,
            selectionmode: "LIST",
            selectableresources: resource.selectableresources,
            startwhen: "ATSTART",
            numofminutes: 0,
            optional: false,
            quantityselector: "GROUPSIZE",
            quantity: resource.quantity,
            mode: "COMPLEX",
            inline: false,
            ignore: false,
            admissions: null,
            inProgress: true,
            originatingid: '',
            wasoptional: false,
            wasselected: false
          }
  
          this.PackageObj.resources.push(newRes);
          newRes = null;
        }
        else
        {
          let newRes: cResource = {
            groupname: "",
            id: uuidv4(),
            resourceId:resource.resourceId,
            name:resource.name,
            useClasses:resource.useClasses,
            exclusive:resource.exclusive,
            eventid: resource.eventid,
            concurrentUses:resource.concurrentUses,
            maxConcurrent:resource.maxConcurrent,
            disabled:resource.disabled,
            venueId:resource.venueId,
            colour:resource.colour,
            orderindex:resource.orderindex,
            length:resource.length,
            classes:resource.classes,
            showonbooking:resource.showonbooking,
            resources:resource.resources,
            isresourcecontainer:resource.isresourcecontainer,
            isconstructed: resource.isconstructed,
            price:resource.price,
            fixedprice:resource.fixedprice,
            resourcegroupname: resource.resourcegroupname,
            tags: resource.tags,
            resourcegroup: resource.resourcegroup,
            selectionmode: "",
            selectableresources: resource.selectableresources,
            startwhen: "ATSTART",
            numofminutes: resource.numofminutes,
            optional: resource.optional,
            quantityselector: "GROUPSIZE",
            quantity: resource.quantity,
            mode: "SIMPLE",
            inline: true,
            ignore: false,
            admissions: null,
            inProgress: true,
            originatingid: '',
            wasoptional: false,
            wasselected: false
          }
  
          this.PackageObj.resources.push(newRes);
          newRes = null;
        }

        this.buildItinerary();
    }

    AddToAdmissions(admission){
      if(this.PackageObj.admissions == null)
        this.PackageObj.admissions = [];

      let newAdm: cAdmission = {
        id: uuidv4(),
        name: admission.name,
        classid:null,
        bookingwizardtext: admission.bookingwizardtext,
        fixedPrice: admission.fixedPrice,
        participant: admission.participant,
        venueid: admission.venueid,
        admissionid: admission.admissionid,
        noofpeople: admission.noofpeople,
        price: admission.price,
        admissionClass: admission.admissionClass,
        min: admission.min,
        max: admission.max,
        index: admission.index,
        disabled: admission.disabled,
        mustinclude: admission.mustinclude,
        eventtickettype: admission.eventtickettype,
        counter: admission.counters,
        linkedadmissions: admission.linkedadmissions,
        uselinkedadmissions: admission.uselinkedadmissions,
        linkeddiscounts: admission.linkeddiscounts,
        uselinkeddiscounts: admission.uselinkeddiscounts,
        maxlinkeddiscounts:admission.maxlinkeddiscounts,
        maxlinkedadmissions:admission.maxlinkedadmissions,
        fixed: admission.fixed,
        onlysetmax: admission.onlysetmax,
      }

      this.PackageObj.admissions.push(newAdm);
    }

    removeFromAdmissions(admission){
      this.PackageObj.admissions = this.PackageObj.admissions.filter(obj => obj.id !== admission.id);
    }

    removeFromResources(resource){
      this.PackageObj.resources = this.PackageObj.resources.filter(obj => obj.id !== resource.id);
      this.buildItinerary();
    }
    
    updateData() {

      if (this.DoValidCheck())
      {
        let AllOK: boolean = true;

        if(this.PackageObj.admissions.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: 'Admission missing', detail: 'You must have at least 1 admission' });
          AllOK = false;
        }

        if(this.PackageObj.resources.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: 'Resource missing', detail: 'You must have at least 1 Resource' });
          AllOK = false;
        }

        let resourceHasNoLength: boolean = false;
        this.PackageObj.resources.forEach(element => {
          if(element.length == 0)
            resourceHasNoLength = true;
        });

        if(resourceHasNoLength)
        {
          this.messageService.add({ severity: 'error', summary: 'Resource length Issue', detail: 'All Resources must have a length greater than 0' });
          AllOK = false;
        }

        if(AllOK)
        {
          this.PackageObj.bookingscannotbemadewithinxminutes = (this.bookingCannotBeMadeWithinXMinutesD * 1440) + (this.bookingCannotBeMadeWithinXMinutesH * 60) + this.bookingCannotBeMadeWithinXMinutesM;

          this.PackageObj.bookingscannotbemadeafterxminutes = this.bookingscannotbemadeafterxDays * 1440;

          this.PackageObj.admissions.forEach(adm => {
            if(!adm.uselinkedadmissions)
            {
              adm.linkedadmissions = null;
            }

            if(!adm.uselinkeddiscounts)
              {
                adm.linkeddiscounts = null;
              }
          });

          let index = 0;
          this.PackageObj.admissions.forEach(adm => {
            adm.index = index;
            index++;
          });

          if (this.id == "0") {

            let index: number = 0;
            this.PackageObj.resources.forEach(res => {
              res.orderindex = index;
              let innerindex: number = 0;
              res.resources.forEach(innerres => {
                innerres.orderindex = innerindex;
                innerindex ++;
              });
              index ++;
            });

            this.PackageObj.resources.forEach(element => {
              if(element.mode == "SIMPLE")
              {
                element.selectionmode = '';
                element.startwhen = '';
              }
            });

            this.PackageObj.name = this.PackageObj.name.trim();

            this.PackageObj.startDate = ConvertDateToDB(this.PackageObj.startDate);
            this.PackageObj.endDate = ConvertDateToDB(this.PackageObj.endDate);

            this.PackageObj.discountstart = ConvertDateToDB(this.PackageObj.discountstart);
            this.PackageObj.discountend = ConvertDateToDB(this.PackageObj.discountend);

            this.http.post<packageObj>(bmxurl + "/package", this.PackageObj).subscribe(response => {
              this.httpResponse = response
              if (this.httpResponse.succeeded) {
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package successfully created' });
                this.router.navigateByUrl("/packagestarttimes/" + this.httpResponse.data.packageId);
              }
              else
              {
                this.messageService.add({ severity: 'error', summary: 'Error Occurred', detail: this.httpResponse.messages[0] });
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
            })
          }
          else
          {
            this.PackageObj.disabled = false;

            let index: number = 0;
            this.PackageObj.resources.forEach(res => {
              res.orderindex = index;
              let innerindex: number = 0;
              res.resources.forEach(innerres => {
                innerres.orderindex = innerindex;
                innerindex ++;
              });
              index ++;
            });

            this.PackageObj.name = this.PackageObj.name.trim();
            
            this.PackageObj.startDate = ConvertDateToDB(this.PackageObj.startDate);
            this.PackageObj.endDate = ConvertDateToDB(this.PackageObj.endDate);

            this.PackageObj.discountstart = ConvertDateToDB(this.PackageObj.discountstart);
            this.PackageObj.discountend = ConvertDateToDB(this.PackageObj.discountend);
            
            this.http.put<packageObj>(bmxurl + "/package", this.PackageObj).subscribe(response => {
              this.httpResponse = response
              if (this.httpResponse.succeeded) {
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package successfully updated' });
                this.router.navigateByUrl("/package");
              }
              else
              {
                this.messageService.add({ severity: 'error', summary: 'Error Occurred', detail: this.httpResponse.messages[0] });
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
            })
          }
        }
      }
    }

    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

    buildItinerary()
    {
      this.delay(1000);

      let checkresources: resource[] = [];

      for (var i = 0; i < this.PackageObj.resources.length; i++)
      {
        let newRes: cResource = {
          groupname: "",
          id: uuidv4(),
          resourceId: this.PackageObj.resources[i].resourceId,
          name: this.PackageObj.resources[i].name,
          useClasses: this.PackageObj.resources[i].useClasses,
          exclusive: this.PackageObj.resources[i].exclusive,
          eventid: this.PackageObj.resources[i].eventid,
          concurrentUses: this.PackageObj.resources[i].concurrentUses,
          maxConcurrent: this.PackageObj.resources[i].maxConcurrent,
          disabled: this.PackageObj.resources[i].disabled,
          venueId: this.PackageObj.resources[i].venueId,
          colour: this.PackageObj.resources[i].colour,
          orderindex: this.PackageObj.resources[i].orderindex,
          length: this.PackageObj.resources[i].length,
          classes: this.PackageObj.resources[i].classes,
          showonbooking: this.PackageObj.resources[i].showonbooking,
          resources: this.PackageObj.resources[i].resources,
          isresourcecontainer: this.PackageObj.resources[i].isresourcecontainer,
          isconstructed:  this.PackageObj.resources[i].isconstructed,
          price: this.PackageObj.resources[i].price,
          fixedprice: this.PackageObj.resources[i].fixedprice,
          resourcegroupname:  this.PackageObj.resources[i].resourcegroupname,
          tags:  this.PackageObj.resources[i].tags,
          resourcegroup:  this.PackageObj.resources[i].resourcegroup,
          selectionmode:  this.PackageObj.resources[i].selectionmode,
          selectableresources:  this.PackageObj.resources[i].selectableresources,
          startwhen:  this.PackageObj.resources[i].startwhen,
          numofminutes:  this.PackageObj.resources[i].numofminutes,
          optional:  this.PackageObj.resources[i].optional,
          quantityselector:  this.PackageObj.resources[i].quantityselector,
          quantity:  this.PackageObj.resources[i].quantity,
          mode:  this.PackageObj.resources[i].mode,
          inline:  this.PackageObj.resources[i].inline,
          ignore:  this.PackageObj.resources[i].ignore,
          admissions: this.PackageObj.resources[i].admissions,
          inProgress: this.PackageObj.resources[i].inProgress,
          originatingid: this.PackageObj.resources[i].originatingid,
          wasoptional: this.PackageObj.resources[i].wasoptional,
          wasselected: this.PackageObj.resources[i].wasselected,
          
        }

        checkresources.push(newRes);
      }

      let startTime: number = 600;
      let minuteOffset: number = 0;
      let sessionLength: number = 0;

      this.itinerary = "";
      this.discrepantitinerary = [];

      checkresources.forEach(innerRes => {
        if (innerRes.inline)
        {
          sessionLength += innerRes.length;
        }
      });

      for (var i = 0; i < checkresources.length; i++)
      {
        if (checkresources[i].inline)
        {
          if(i == 0)
          {
            minuteOffset += 0;
            checkresources[i].orderindex = startTime + minuteOffset;
          }
          else
          {
            minuteOffset += checkresources[i - 1].length;
            checkresources[i].orderindex = startTime + minuteOffset;
          }
        }
        else
        {
          if(checkresources[i].startwhen == "ATEND")
          {
            checkresources[i].orderindex = startTime + sessionLength;
          }
          else if(checkresources[i].startwhen == "ATSTART")
          {
            checkresources[i].orderindex = startTime;
          }
          else if(checkresources[i].startwhen == "OFFSET")
          {
            checkresources[i].orderindex = startTime + checkresources[i].numofminutes;
          }
        }
      }

      checkresources = checkresources.sort((r1, r2) => {
          if (r1.orderindex > r2.orderindex) {
              return 1;
          }
      
          if (r1.orderindex < r2.orderindex) {
              return -1;
          }
      });

      minuteOffset = 0;

      for (var i = 0; i < checkresources.length; i++)
      {
        if(checkresources[i].startwhen == "ATSTART" && checkresources[i].inline == false)
        {
          this.itinerary += this.convertTime(startTime) + " - " + checkresources[i].name + " (" + checkresources[i].length + (checkresources[i].length == 1 ? " minute), " : " minutes), ");
        }
        else if(checkresources[i].startwhen == "ATEND" && checkresources[i].inline == false)
        {
          this.itinerary += this.convertTime(startTime + sessionLength) + " - " + checkresources[i].name + " (" + checkresources[i].length + (checkresources[i].length == 1 ? " minute), " : " minutes), ");
        }
        else
        {
          this.itinerary += this.convertTime(startTime + minuteOffset) + " - " + checkresources[i].name + " (" + checkresources[i].length + (checkresources[i].length == 1 ? " minute), " : " minutes), ");
          minuteOffset += checkresources[i].length;
        }
      }
     
      if (this.itinerary.length > 2)
      {
        this.itinerary = this.itinerary.substring(0, this.itinerary.length - 2)
      }
    }

    convertTime(mins: number)
    {
        var hours = Math.floor(mins / 60);          
        var minutes = mins % 60;
      
        return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
    }

    NextRes(){  
      this.activeIndex = 1;
    }

    NextAds(){
      this.activeIndex = 2;
    }

    removeImage(){
      this.FileChosen = null;
      this.PackageObj.imageurl = null;
    }

    DoValidCheck()
    {
      let AllValid = true;


      if(this.PackageObj.publicDepositPolicy == null)
      {
        this.publicDepositPolicy = false;
        AllValid = false;
      }
      else
      {
        this.publicDepositPolicy = true;
      }

      if(this.PackageObj.name == null || this.PackageObj.name == "")
      {
        AllValid = false;
        this.packageNameValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Name Required', detail: 'You must provide a name for your Package, please return to the first tab and correct this.' });
      }
      else
      {
        this.packageNameValid = true;
      }

      if(this.PackageObj.description == null || this.PackageObj.description == "")
      {
        AllValid = false;
        this.packageDescValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Description required', detail: 'You must provide a description for your Package, please return to the first tab and correct this.'});
      }
      else
      {
        this.packageDescValid = true;
      }

      return AllValid;
    }

    
}

class UTCDate {
  private boundDateBacker: Date;
  constructor(private dateInstance: Date) {
    this.boundDateBacker = this.utcToLocal(dateInstance);
  }

  public get boundDate(): Date {
    return this.boundDateBacker;
  }
  public set boundDate(value: Date) {
    if (value) {
      this.dateInstance.setTime(this.localToUtc(value).getTime());
      const newTime = value.getTime();
      if (newTime !== this.boundDateBacker.getTime()) {
        this.boundDateBacker.setTime(newTime);
      }
    }
  }

  private localToUtc(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),  date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  private utcToLocal(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),  date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
}
