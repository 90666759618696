import { Component, OnInit } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { bmxurl } from 'src/globals';
import { booking_returndata } from 'src/types/booking_returndata';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BusyService } from '../_services/busy.service';
import { GlobalService } from '../_services/global.service';
import { MessageService } from 'primeng/api';
import { bookingnumbers, cBookingnumbers } from 'src/types/bookingnumbers';
import { bookingnumbers_returndata } from 'src/types/bookingnumbers_retunrdata';
import { session } from 'src/types/session';
import { session_returndata } from 'src/types/session_returndata';
import { cCounterValues } from 'src/types/countervalues';
import { countervalues_returndata } from 'src/types/countervalues_returndata';
import { stringify } from 'querystring';

@Component({
  selector: 'app-ataglance',
  templateUrl: './ataglance.component.html',
  styleUrls: ['./ataglance.component.css']
})
export class AtaglanceComponent implements OnInit {

  ListBookings: any[];
  ListBookingsGlobal: any[];
  Bookings: any;
  data: any;
  options: any;
  hubConnection: HubConnection;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  showStarted: boolean = false;
  bookingnumbers: cBookingnumbers[];
  BookingDate: Date;
  bookingsInProgress: number = 0;
  totalBookingsToday: number = 0;
  inProgress: boolean = false;
  startdate: string = "";
  httpResponse: any;
  bookingnumbersfiltered: any;
  UseCounters: boolean = false;
  Counters: cCounterValues = {
    venueid: "",
    participants: 0,
    nonparticipants: 0,
    timeremaining: 0,
    timeusedup: 0,
    total: 0
  };
  PreviousHubs: string[] = [];

  sessionArray: session[] = []

  ShowCounterStrip: boolean = true;

  constructor(private route: ActivatedRoute,
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) { 
  }

  doneDate(){
    this.loadgrid()

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.BookingDate = this.addDays(new Date(this.BookingDate), num);
    this.doneDate();

  }

  bookingblock(inTime){
    //get just date portion of BookingDate
    //let BookingDateYear = this.BookingDate.getFullYear();
    //let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    //let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    //let NewDate = new Date(BookingDateYear + "-" + BookingDateMonth + "-" + BookingDateDay + " " + inTime + ":00");

    this.router.navigate(['/bookingblock/' + this.stringifyDate(this.BookingDate) + "T" + inTime + ":00.000"]);
  }

  stringifyDate(date: Date): string
  {
    let returnString = "";

    returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2);

    return returnString;
  }


  admitAll(time, id, started){
    let BookingDateYear = this.BookingDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let NewDate = BookingDateDay + BookingDateMonth + BookingDateYear;

    //let timeChosen = ('00' + this.BookingDate.getHours()).slice(-2) + ('00' + this.BookingDate.getMinutes()).slice(-2);
    let timeChosen = time.replace(":", "");

    this.http.get(bmxurl + "/groupcheckin/" + this.VenueID + "/" + NewDate + "/" + timeChosen).subscribe(response => {
      this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.loadgrid();
          this.messageService.add({severity:'success', summary:'Admitted', detail:'All customers admitted for this time slot.'});
        }
        }, error => {
          this.messageService.add({severity:'error', summary:'Could not admit all customers', detail:error.messages});
      })

      this.startSession(time, id, false);
  }

  leaveAll(time, id, started){
    let BookingDateYear = this.BookingDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let NewDate = BookingDateDay + BookingDateMonth + BookingDateYear;

    //let timeChosen = ('00' + this.BookingDate.getHours()).slice(-2) + ('00' + this.BookingDate.getMinutes()).slice(-2);
    let timeChosen = time.replace(":", "");

    this.http.get(bmxurl + "/groupcheckout/" + this.VenueID + "/" + NewDate + "/" + timeChosen).subscribe(response => {
      this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.loadgrid();
          this.messageService.add({severity:'success', summary:'Admitted', detail:'All customers left for this time slot.'});
        }
        }, error => {
          this.messageService.add({severity:'error', summary:'Could not leave all customers', detail:error.messages});
      })

      //this.startSession(time, id, true);
  }

  startSession(time, id, started){
    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let NewDate = new Date(BookingDateYear + "-" + BookingDateMonth + "-" + BookingDateDay + " " + time + ":00");

    if(id == null)
    {
      let NewSession : session = {
        id: null,
        venueid: this.VenueID,
        datetime: NewDate,
        started: true
      }

      this.http.post<any>(bmxurl + "/CountersSession", NewSession).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Started', detail:'This session has been started.'});
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Could not start or stop this session', detail:error.messages});
        })
    }
    else
    {
      let NewSession : session = {
        id: id,
        venueid: this.VenueID,
        datetime: NewDate,
        started: !started
      }

      this.http.post<any>(bmxurl + "/CountersSession", NewSession).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            if(started)
            {
              this.messageService.add({severity:'success', summary:'Started', detail:'This session has been Stopped.'});
            }
            else
            {
              this.messageService.add({severity:'success', summary:'Stopped', detail:'This session has been Started.'});
            }
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Could not start or stop this session', detail:error.messages});
        })
    }

    this.bookingnumbers.forEach(bn => {
      if(bn.time == time)
      {
        bn.started = !started;
      }
    });

    if(this.showStarted)
    {
      this.bookingnumbersfiltered = this.bookingnumbers;
    }
    else
    {
      this.bookingnumbersfiltered = this.bookingnumbers;
      this.bookingnumbersfiltered = this.bookingnumbers.filter(bn => bn.started == false || bn.started == null);
    }

  }

  loadgrid()
  {
    //Get date for today
    let TodayDateYear = new Date().getFullYear().toString().substr(-2);
    let TodayDateMonth = ('00' + (new Date().getMonth() + 1)).slice(-2);
    let TodayDateDay = ('00' + new Date().getDate()).slice(-2);

    let todayDate = TodayDateDay + TodayDateMonth + TodayDateYear;

    //get countersessions for today
    let BookingDateYear = this.BookingDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    if(todayDate == dateChosen)
    {
      this.ShowCounterStrip = true;
    }
    else
    {
      this.ShowCounterStrip = false;
    }

    //this.busyService.busy();
    this.http.get(bmxurl + "BookingListMinGrouped/" + this.VenueID + "/" + dateChosen + "/false").subscribe(response => {
      try{
        this.bookingnumbers = (response as bookingnumbers_returndata).data;

        let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
        let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);
    
        let BookingDateYearForCounters = this.BookingDate.getFullYear().toString();
    
        let venueDate = this.VenueID + "_" + BookingDateDay + BookingDateMonth + BookingDateYearForCounters;
    
        this.http.get(bmxurl + "CountersSessionList/" + venueDate).subscribe(response => {
          this.sessionArray = (response as session_returndata).data;
          this.bookingnumbers.forEach(bn => {
            if(this.sessionArray != null && this.sessionArray.length > 0)
            {
              this.sessionArray.forEach(session => {
                //add time to dateChosen
                let BookingDateYear = "20" + dateChosen.substring(4,8);
                let BookingDateMonth = dateChosen.substring(2,4);
                let BookingDateDay = dateChosen.substring(0,2);
                
                let NewDate = new Date(BookingDateYear + "-" + BookingDateMonth + "-" + BookingDateDay + " " + bn.time + ":00");

                if((new Date(session.datetime).getTime()) == (new Date(NewDate).getTime()))
                {
                  bn.id = session.id;
                  bn.started = session.started;
                }
              });
            }
          });

          this.bookingnumbersfiltered = this.bookingnumbers.filter(bn => bn.started == false || bn.started == null);

          //Get the number for counters
          this.http.get(bmxurl + "todayscounters/" + this.VenueID).subscribe(response => {
            this.Counters = (response as countervalues_returndata).data;
          });

        }, error => {
          this.messageService.add({severity:'error', summary:'Error getting sessions', detail: 'An error occurred getting sessions'});
        });

      }
      catch{
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  ngOnInit(): void {

    this.UseCounters = localStorage.getItem("UseCounters") == "true" ? true : false;

    this.BookingDate = new Date();
    this.startdate = this.route.snapshot.paramMap.get('startdate');

    if(this.startdate != null)
    {
      this.BookingDate = new Date(this.startdate);
    }

    // this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
    //   this.hubConnection = new HubConnectionBuilder()
    //   .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
		//   accessTokenFactory: () => tokenReturned 
	  // }).build();

    this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
      this.hubConnection = new HubConnectionBuilder()
	    .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
		  accessTokenFactory: () => tokenReturned 
	  }).build();

    // this.hubConnection.start();

    // this.hubConnection.on("bookingEvent", data => {
    //   //this.messageService.add({severity:'success', summary:'Booking Made', detail: 'A new booking has been made. (' + data.name + ')'});
    //   this.loadgrid();
    // });

    // this.hubConnection.on("countervalues", data => {
    //   this.messageService.add({severity:'success', summary:'Booking Made', detail: 'COUNTERS HAVE CHANGED'});
      
    // });

    // });

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.PreviousHubs.push(BookingEventDate);

    this.hubConnection.start();

    this.hubConnection.off(BookingEventDate);

    this.hubConnection.on(BookingEventDate, data => {
      this.loadgrid();
    });

    this.hubConnection.on("countervalues", data => {
      //this.messageService.add({severity:'success', summary:'Booking Made', detail: 'COUNTERS HAVE CHANGED'});
      this.Counters = data;
    });

    });
    
    this.loadgrid();

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    
    this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }

        }
    };

  }

  bookSession(time){
    //let BookingDateYear = this.BookingDate.getFullYear();
    //let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    //let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    //let NewDate = new Date(BookingDateYear + "-" + BookingDateMonth + "-" + BookingDateDay + " " + time + ":00");

    let BookingDateTime: string = this.BookingDate.getFullYear() + "-" +  ('00' + (Number(this.BookingDate.getMonth()) + 1)).slice(-2) + "-" +  ('00' + this.BookingDate.getDate()).slice(-2)+ "T" + time + ":00";
     
    this.router.navigateByUrl("/booking/0?bookingDate=" + BookingDateTime);
  }

  toggleStarted(){
    this.showStarted = !this.showStarted;

    if(this.showStarted)
    {
      this.bookingnumbersfiltered = this.bookingnumbers;
    }
    else
    {
      this.bookingnumbersfiltered = this.bookingnumbers;
      this.bookingnumbersfiltered = this.bookingnumbers.filter(bn => bn.started == false || bn.started == null);
    }
  }

}
