<p-dialog header="Change PIN" [(visible)]="showPasswordChange" [style]="{width: '30vw'}" [baseZIndex]="10000"  [modal]="true">
    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:5px;">
    <div class="field mb-4 col-12">
        <p>Please change your PIN</p>
        New PIN
        <input id="pinPadNewCode" pKeyFilter="int" pInputText type="text" maxlength="4" type="password" style="font-size: 36px;width:100%;text-align: center;" [(ngModel)]="NewPIN"/><br><br>
        Repeat New PIN
        <input pKeyFilter="int" pInputText type="text" maxlength="4" type="password" style="font-size: 36px;width:100%;text-align: center;" [(ngModel)]="RepeatNewPIN"/>
    </div>
    <div *ngIf="!PasswordsMatch" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your PINs do not match.</div>
</div>
<ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Change PIN" styleClass="p-button p-button-success" (click)="doPINChange()"></p-button>
</ng-template>

</p-dialog>

        <p-dialog [(visible)]="showPINPad" header="{{PINHeader}}" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
            <div style="width: 100%;float:left">
                <div>
                    <input id="pinPadCode" pInputText type="password" maxlength="6" [(ngModel)]="pinNumber" style="text-align: center; text-align: center;height: 83px;padding-left: 33px;font-size: 40px;width: 100%;margin-bottom: 20px;margin-left: 10px;" (keyup.enter)="login()">
                </div>
                <div>
                    <button (click)="doButtonPress('1')" style="margin-bottom: 10px; margin-right:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">1</span>
                    </button>
                    <button (click)="doButtonPress('2')" style="margin-bottom: 10px; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">2</span>
                    </button>
                    <button (click)="doButtonPress('3')" style="margin-bottom: 10px; margin-left:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">3</span>
                    </button>
                </div>

                <div>
                    <button (click)="doButtonPress('4')" style="margin-bottom: 10px; margin-right:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">4</span>
                    </button>
                    <button (click)="doButtonPress('5')" style="margin-bottom: 10px; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">5</span>
                    </button>
                    <button (click)="doButtonPress('6')" style="margin-bottom: 10px; margin-left:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">6</span>
                    </button>
                </div>

                <div>
                    <button (click)="doButtonPress('7')" style="margin-bottom: 10px; margin-right:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">7</span>
                    </button>
                    <button (click)="doButtonPress('8')" style="margin-bottom: 10px; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">8</span>
                    </button>
                    <button (click)="doButtonPress('9')" style="margin-bottom: 10px; margin-left:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">9</span>
                    </button>
                </div>

                <div>
                    <button (click)="clear()" style="margin-bottom: 10px; margin-right:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">Clear</span>
                    </button>
                    <button (click)="doButtonPress('0')" style="margin-bottom: 10px; width: 32%; height: 20%; font-size: 40px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">0</span>
                    </button>
                    <button (click)="login()" style="margin-bottom: 10px; margin-left:1.5%; width: 32%; height: 20%; font-size: 40px;" pButton  type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <span style="margin: auto;width: 100%;">Login</span>
                    </button>
                </div>
            </div>
</p-dialog>

<div class="p-3 flex flex-column flex-auto">
    <div>
      <div class="text-900 font-medium text-xl mb-3">
        <h3>Login</h3>
      </div>
      <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span> Please select your name from the list and type in your PIN.
      </p>

      <div class="surface-card p-4 shadow-2 border-round" style="min-height: 65vh;">
        <div class="grid formgrid p-fluid">
            <div style="width: 100%;float:left;">
                <div style="padding-right: 10%; height: 94px;font-size: 35px;font-weight: bold;">{{selectedUser}}</div>
                <div *ngFor="let u of Users" style="padding-left:10px; padding-right: 10px;">
                    <button (click)="selectName(u.name, u.userid, u.temppin)" style="margin-bottom:20px;float:left; width: 24%; margin-right:1%; height: 75px; font-size: 20px; font-weight:bold; background-color: #b8c7db !important; color:#222222" pButton type="button" class="p-button-sm p-element p-ripple p-button-secondary p-button p-component">
                        <div style="text-align: center;">{{u.name}}</div>
                    </button>
                </div>
            </div>

        </div>
      </div>
    </div>
</div>