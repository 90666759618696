<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
      <div class="text-900 font-medium text-xl mb-3">
        <h3>Venue Settings</h3>
      </div>

      <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
          <p-tabView style="width:100%">
            <p-tabPanel header="Venue Details" leftIcon="pi pi-home">
              <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
                <span class="pi pi-info-circle" style="margin-right:2px;"></span> Basic venue information.
              </p>
                <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Name</label>
                    <input id="VenueName" [(ngModel)]="Venue.data.name" [ngStyle]="{'border-color': (venueNameValid==false) ? '#FF0000':''}"  type="text" pInputText>
                  </div>
                  <div *ngIf="!venueNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Venue name.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Address 1</label>
                    <input  id="Address1" [(ngModel)]="Venue.data.address1" [ngStyle]="{'border-color': (addressValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!addressValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Address.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Address 2</label>
                    <input id="Address2" [(ngModel)]="Venue.data.address2"  type="text" pInputText>
                  </div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Town/City</label>
                    <input  id="County" [(ngModel)]="Venue.data.town" [ngStyle]="{'border-color': (townValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!townValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Town/City.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">County</label>
                    <input  id="County" [(ngModel)]="Venue.data.county" [ngStyle]="{'border-color': (countyValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!countyValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a County.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">PostCode</label>
                    <input id="PostCode"  [(ngModel)]="Venue.data.postcode" [ngStyle]="{'border-color': (postcodeValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" (change)="getLatLong(Venue.data.postcode)" pInputText>
                  </div>
                  <div *ngIf="!postcodeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Postcode.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Telephone</label>
                    <input id="Telephone" [(ngModel)]="Venue.data.telephone" [ngStyle]="{'border-color': (phoneValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!phoneValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Telephone number.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Email</label>
                    <input id="Email" [(ngModel)]="Venue.data.email" [ngStyle]="{'border-color': (emailValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!emailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Email.</div>
                  <div style="display:none;">
                    <div class="field mb-4 col-12">
                      <label for="packageobjname" class="font-medium">Latitude</label>
                      <input id="Latitude"  type="text"  [(ngModel)]="Venue.data.lat" readonly="true" pInputText>
                    </div>
                    <div class="field mb-4 col-12">
                      <label for="packageobjname" class="font-medium">Longitude</label>
                      <input id="Longitude"  [(ngModel)]="Venue.data.lon" type="text" readonly="true" pInputText>
                    </div>
                </div>
                  <div class="field mb-4 col-12">
                    <label for="venueStartTime" class="font-medium">Venue Opening Time</label>
                    <br>
                    <p-dropdown [options]="venueStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <div style="clear:both;"></div>
                  </div>
                  <div class="field mb-4 col-12">
                    <label for="venueEndTime" class="font-medium">Venue Closing Time</label>
                    <br>
                    <p-dropdown [options]="venueEndTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedEndTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueEndTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedEndTimeMins" optionValue="code" optionLabel="name"></p-dropdown>

                    <div style="clear:both;"></div>
                    
                  </div>
                  
                  <div class="field mb-4 col-12">
                    <label for="capacity" class="font-medium">Venue Capacity</label>
                    <p-inputNumber id="capacity" [style]="{'width': '300px'}" [(ngModel)]="Venue.data.capacity" [showButtons]="true" max="9999" min="0" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="DoValidCheck()"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="includeParticipants" class="font-medium">Include Participants in Venue Capacity</label>
                    <p-selectButton [(ngModel)] = "VCapacityIncludesParticipant" [style]="{'width': '150px'}" [options]="includeParticipants" ></p-selectButton>
                  </div>

                  <div *ngIf="!capacityValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Venue Capacity greater than zero.</div>

                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Booking ID Prefix</label>
                    <input id="prefix" [(ngModel)]="this.Venue.data.prefix" [maxlength]="4" [ngStyle]="{'border-color': (prefixValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!prefixValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Prefix.</div>
                  
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Public Booking Link</label>
                    <input id="prefix" [(ngModel)]="PublicHostName" [readOnly]="true" type="text" pInputText>
                  </div>

                  <div class="col-12"> 
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnHome()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="Venue Start Times" leftIcon="pi pi-clock">
              <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
                <span class="pi pi-info-circle" style="margin-right:2px;"></span> The opening hours for your venue.
              </p>
              <p-accordion [style]="{'width':'100%'}">
                <p-accordionTab >
                  <p-header style="width:100%">
                    <div style="width:100%">
                        <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Monday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{mondaySelectedTimes.length}}&nbsp;times selected)</span>
                    </div>
                </p-header>
                <div class="field mb-4 col-12">
                  <div class="field mb-4 col-12">
                    <label for="MondayTimes" class="font-medium">Selected Times</label>
                    <div *ngFor="let t of mondaySelectedTimes">
                        <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveMondayChip(t.time)"></p-chip>
                        <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveMondayChip(t.time)"></p-chip>
                      </div>
                    <div style="clear:both; margin-top:65px;"></div> 

                    <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                      <div style="margin-top:10px; margin-bottom:10px;">
                        Template
                      </div>
                      <p-dropdown [options]="mondayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="mondaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                      <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedMonday()">
                        <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                        <span>Add</span>
                      </button>
                    </div>

                    <div style="width: 424px; float: left;margin-top: 10px;">
                      <div style="margin-top:10px; margin-bottom:10px;">
                        Ad Hoc
                      </div>
                        <p-dropdown [options]="venueMondayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueMondaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                        <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                        <p-dropdown [options]="venueMondayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueMondaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                      
                      <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocMonday()">
                        <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                        <span>Add</span>
                      </button>

                    </div>
                    <div style="float:left; margin-top:20px; text-align:center;">
                      <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeek')">
                        <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                        <span>Copy to all Week</span>
                      </button>

                      <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeekExcWE')">
                        <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                        <span>Copy to all Week (Excl Weekend)</span>
                      </button>

                      <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeekEnd')">
                        <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                        <span>Copy to Weekend</span>
                      </button>

                      <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesMonday()">
                        <span class="pi pi-times" style="margin-right:8px;"></span>
                        <span>Clear Times</span>
                      </button>
                    </div>
                    
                  </div>
                </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Tuesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{tuesdaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="TuesdayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of tuesdaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveTuesdayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveTuesdayChip(t.time)"></p-chip>
                 
                  </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="tuesdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="tuesdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedTuesday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueTuesdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueTuesdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueTuesdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueTuesdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocTuesday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesTuesday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                 
                </div> 
              </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Wednesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{wednesdaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="WednesdayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of wednesdaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveWednesdayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveWednesdayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="wednesdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="wednesdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedWednesday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueWednesdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueWednesdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueWednesdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueWednesdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocWednesday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesWednesday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                </div>
                
              </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Thursday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{thursdaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="ThursdayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of thursdaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveThursdayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveThursdayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="thursdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="thursdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedThursday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueThursdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueThursdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueThursdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueThursdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocThursday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesThursday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                </div>
                
              </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Friday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{fridaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="FridayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of fridaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveFridayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveFridayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="fridayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="fridaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedFriday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueFridayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueFridaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueFridayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueFridaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocFriday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesFriday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                  
                </div>
              </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Saturday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{saturdaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="SaturdayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of saturdaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSaturdayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSaturdayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="saturdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="saturdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedSaturday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueSaturdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSaturdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueSaturdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSaturdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocSaturday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesSaturday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                  
                </div>
              </div><div style="clear:both;"></div>
              </p-accordionTab>
              <p-accordionTab>
                <p-header style="width:100%">
                  <div style="width:100%">
                      <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Sunday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{sundaySelectedTimes.length}}&nbsp;times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="SundayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of sundaySelectedTimes">
                      <p-chip *ngIf="(t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSundayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSundayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="sundayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="sundaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedSunday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueSundayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSundaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueSundayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSundaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocSunday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesSunday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                  
                </div>
              </div><div style="clear:both;"></div>
              </p-accordionTab>
            </p-accordion>
            <div class="col-12"> 
              <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateStartTimeData();">
              <span class="pi pi-pencil" style="margin-right:8px;"></span>
              Save Changes</button>
              &nbsp;
              <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnHome()">
              <span class="pi pi-times" style="margin-right:8px;"></span>
              Cancel</button>
          </div>
            </p-tabPanel> -->
            <p-tabPanel header="Settings" leftIcon="pi pi-cog">
              <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
                <span class="pi pi-info-circle" style="margin-right:2px;"></span> Ad hoc information for this venue.
              </p>
              <p-accordion>
                <p-accordionTab>

                  <p-header style="width:100%">
                    <div style="width:100%">
                        <span class="pi pi-cog" style="margin-right: 10px;"></span>
                        General Settings
                    </div>
                  </p-header>

                  <div class="field mb-4 col-12">
                    <label for="diagnosticsMode" class="font-medium">Availability Diagnostics Mode</label>
                    <p-selectButton [(ngModel)] = "vsDiagnostics" [style]="{'width': '150px'}" [options]="diagnosticsModeStateOptions" ></p-selectButton>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="minimalDetails" class="font-medium">Use Minimal Customer Details</label>
                    <p-selectButton [(ngModel)] = "vsMinimalDetails" [style]="{'width': '150px'}" [options]="minimalDetailsStateOptions" ></p-selectButton>
                  </div>

                  <!-- <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Send Email/SMS Confirmations</label>
                    <p-selectButton [(ngModel)] = "vsConfirmations" [style]="{'width': '300px'}" [options]="ConfirmationsStateOptions" ></p-selectButton>
                  </div> -->

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Default Interval for Day Sheets</label>
                    <p-inputNumber id="defInterval" [style]="{'width': '300px'}" [(ngModel)]="defInterval== null ? 1: defInterval" [showButtons]="true" max="240" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="minimalDetails" class="font-medium">Session Timeout?</label>
                    <p-selectButton [(ngModel)] = "AllowTimeOut" [style]="{'width': '150px'}" [options]="minimalDetailsStateOptions" ></p-selectButton>
                  </div>

                  <div *ngIf="AllowTimeOut" class="field mb-4 col-12">
                    <label for="" class="font-medium">Time out for idle sessions in seconds</label>
                    <p-inputNumber id="idleTimeout" [style]="{'width': '300px'}" [(ngModel)]="idleTimeout== null ? 1: idleTimeout" [showButtons]="true" max="3600" min="10" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="UseCounters" class="font-medium">Use Counters</label>
                    <p-selectButton [(ngModel)] = "UseCounters" [style]="{'width': '150px'}" [options]="minimalDetailsStateOptions" ></p-selectButton>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="SummaryItinerary" class="font-medium">Show Itinerary on summary</label>
                    <p-selectButton [(ngModel)] = "SummaryItinerary" [style]="{'width': '150px'}" [options]="intinerarySummaryStateOptions" ></p-selectButton>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Google Tag Manager ID</label><br>
                    <input style="width:300px;" [(ngModel)]="GoogleTagManagerID" id="hexcolour" type="text" [maxlength]="12" pInputText>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Return page for public booking after payment</label><br>
                    <input style="width:300px;" [(ngModel)]="returnPage" id="returnPage" type="text" [maxlength]="255" pInputText>
                  </div>

                  <div class="field mb-4 col-12" style="width:100%">
                    <label for="" class="font-medium">Currency</label><br>
                    <p-dropdown [options]="Currencies" [style]="{'width': '300px', 'float': 'left'}"  [(ngModel)]="chosenCurrency" optionLabel="name" optionValue="code"></p-dropdown>
                  </div>

                  <div style="float:clear;width:100%;">&nbsp;</div>
                  
                  <div class="field mb-4 col-12" style="width:100%;margin-top:20px;">
                    <label for="" class="font-medium">Timezone</label><br>
                    <p-dropdown [options]="timezones" [style]="{'width': '300px', 'float': 'left'}"  [(ngModel)]="chosenTimezone" optionLabel="name" optionValue="value"></p-dropdown>
                  </div>

                  <div style="float:clear;width:100%;">&nbsp;</div><br>
                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Booking Notification Email</label><br>
                    <input style="width:300px;" [(ngModel)]="BNemail" id="returnPage" type="text" [maxlength]="255" pInputText>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="BookingNotification" class="font-medium">Send Booking Notification</label>
                    <p-selectButton [(ngModel)]="BookingNotification" [style]="{'width': '150px'}" [options]="BNStateOptions" ></p-selectButton>
                  </div>

                  
                  <div style="float:clear;width:100%;">&nbsp;</div>
<!-- 
                  <div style="float:clear;width:100%;height:47px;">&nbsp;</div> -->

                  <!-- <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Bookings cannot be made within this many minutes e.g. 180 (60mins x 3 hrs) would mean if a customer is trying to book for today then they would see time slots in 3 hours time even if you could fit them in sooner.</label>
                    <p-inputNumber id="BookingsCannotBeMadeWithinXMinutes" [style]="{'width': '300px'}" [(ngModel)]="BookingsCannotBeMadeWithinXMinutes== null ? 0: BookingsCannotBeMadeWithinXMinutes" [showButtons]="true" max="525600" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Bookings cannot be made after this many minutes. e.g 4320 (60mins x 24hrs x 3days) would mean if a customer is trying to book, they would not see this package if they pick a date which is more than 3 days from now. </label>
                    <p-inputNumber id="BookingsCannotBeMadeAfterXMinutes" [style]="{'width': '300px'}" [(ngModel)]="BookingsCannotBeMadeAfterXMinutes== null ? 0: BookingsCannotBeMadeAfterXMinutes" [showButtons]="true" max="525600" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Can bookings be made for today? (If 'no' bookings cannot be made for the current day regardless of the time of day)</label>
                    <p-selectButton id="CannotMakeBookingsToday" [(ngModel)] = "CannotMakeBookingsToday" [style]="{'width': '150px'}" [options]="delayBookingStateOptions" ></p-selectButton>
                  </div> -->

              </p-accordionTab>
                <p-accordionTab>

                  <p-header style="width:100%">
                    <div style="width:100%">
                        <span class="pi pi-image" style="margin-right: 10px;"></span>
                          Visual Settings
                    </div>
                  </p-header>


                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Public Image URL</label>
                    <input id="publicImageURL" pInputText type="text" pInputText [(ngModel)]="vsImageURL"/>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Public Image width (in pixels)</label>
                    <p-inputNumber id="defInterval" [style]="{'width': '300px'}" [(ngModel)]="imageWidth" [showButtons]="true" max="500" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Public Image height (in pixels)</label>
                    <p-inputNumber id="defInterval" [style]="{'width': '300px'}" [(ngModel)]="imageHeight" [showButtons]="true" max="500" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                  </div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Default Colour</label><br>
                    <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="defaultColour"></p-colorPicker>
                    <input style="width:200px;" [(ngModel)]="defaultColour" id="hexcolour" type="text" pInputText>
                  </div>
                </p-accordionTab>

                <p-accordionTab>

                  <p-header style="width:100%">
                    <div style="width:100%">
                        <span class="pi pi-money-bill" style="margin-right: 10px;"></span>
                          Payment Settings
                    </div>
                  </p-header>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Payment Sub Account ID</label><br>
                    <input style="width:300px;" [(ngModel)]="RyftSubAccountID" id="hexcolour" type="text" pInputText>
                  </div>
                  
                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Payment Link Template</label><br>
                    <p-dropdown [options]="EmailTemplates" [style]="{'width': '300px', 'float': 'left'}"  placeholder="Select a template" [showClear]="true" [(ngModel)]="selectedPaymentLinkEmailTemplate" optionLabel="name"></p-dropdown>
                  </div>
                  <div style="clear:both;width:100%;height:20px;"></div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Payment Link Expired Template</label><br>
                    <p-dropdown [options]="EmailTemplates" [style]="{'width': '300px', 'float': 'left'}"  placeholder="Select a template" [showClear]="true" [(ngModel)]="selectedCancelledBookingEmailTemplate" optionLabel="name"></p-dropdown>
                  </div>
                  <div style="clear:both;width:100%;height:20px;"></div>

                  <div class="field mb-4 col-12">
                    <label for="" class="font-medium">Voucher Email Template</label><br>
                    <p-dropdown [options]="EmailTemplates" [style]="{'width': '300px', 'float': 'left'}"  placeholder="Select a template" [showClear]="true" [(ngModel)]="selectedVoucherEmailTemplate" optionLabel="name"></p-dropdown>
                  </div>

                  <div style="clear:both;width:100%;height:20px;"></div>
                </p-accordionTab>

              </p-accordion >

              <div class="col-12"> 
                <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateSettingsData();">
                <span class="pi pi-pencil" style="margin-right:8px;"></span>
                Save Changes</button>
                &nbsp;
                <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnHome()">
                <span class="pi pi-times" style="margin-right:8px;"></span>
                Cancel</button>
            </div>
            </p-tabPanel>
            
        </p-tabView>
        </div>
    </div>
</div>
<style>

:host ::ng-deep .p-chip.custom-chip3 {
    background: #EE0000;
    color: var(--primary-color-text);
    width: 90px;
  }

:host ::ng-deep .p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
  width: 90px;
}

.p-chip-text{
  width: 90px;
}
</style>