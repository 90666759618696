import { cResource } from "./resource";


export interface resourceclosings {
    id: string;
    resourceclosingid: string;    
    name: string;  
    resourcetoclose: cResource;
    disabled: boolean;  
    venueid: string;  
    startdate: Date,
    enddate: Date,
    publicmessage: string;
    venuemessage: string;
  }

  export class cResourceclosings implements resourceclosings {
    id: string;
    resourceclosingid: string;    
    name: string;  
    resourcetoclose: cResource;
    disabled: boolean;  
    venueid: string;  
    startdate: Date;
    enddate: Date;
    publicmessage: string;
    venuemessage: string;
  }