<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>
  
  <p-dialog header="Event Conflict" [(visible)]="showDialog" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <div><span class="pi pi-exclamation-triangle" style="font-size: 2.5rem;width: 75px;float: left;padding: 14px;color: #EF4444;; float:left;"></span></div>
    <div style="width:85%; float:left;"><b>This event has been changed by another user, you can choose to refresh the page to see those changes or carry on - if you save your changes you will overwrite the changes that have already been made.</b></div>
  
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-refresh" label="Refresh page" styleClass="p-button p-button-success" (click)="doRefresh()"></p-button>
      &nbsp;
      <p-button icon="pi pi-times" (click)="showDialog=false" label="Continue Editing" styleClass="p-button p-button-danger"></p-button>
  </ng-template>
  
  </p-dialog>
  
  
  <div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
      <div class="text-900 font-medium text-xl mb-3">
        <h3>Event - {{EventObj.name}}</h3>
      </div>
      
      <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span> Define your event here. It is made up of 3 sections Event Details, Resources and Tickets all 3 sections must be completed.
      </p>
  
      <!--<div *ngIf="id=='0'" class="surface-card p-4 shadow-2 border-round" style="margin-bottom: 20px;">    
        <p-steps [model]="items"></p-steps>
      </div>-->
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
          <p-tabView style="width:100%;" [(activeIndex)]="activeIndex">
            <p-tabPanel header="Event Details" leftIcon="pi pi-box">
              <div class="field mb-4 col-12">
                <label for="EventObjname" class="font-medium">Name</label>
                <div style="clear:both;"></div>
                <input [(ngModel)]="EventObj.name" [ngStyle]="{'border-color': (eventNameValid==false) ? '#FF0000':''}" id="EventObjname" type="text" pInputText>
              </div>
              <div *ngIf="!eventNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Event Name.</div>
  
              <div class="field mb-4 col-12">
                <label for="EventObjdescription" class="font-medium">Description</label>
                <p-editor [(ngModel)]="EventObj.description" [style]="{'height':'320px'}"></p-editor>
              </div>
              <div *ngIf="!eventDescValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Event Description.</div>
  
              <div style="clear:both;width:100%">&nbsp;</div>
  
              <div class="field mb-4 col-12">
  
                <label for="EventObjFile" class="font-medium">Upload a header image for Booking Wizard. The image must be smaller than 100KB and should have the ratio 4 (width) to 1 (height) e.g 1000px X 250px</label>
                <div style="clear:both;width:100%">&nbsp;</div>
                <p-fileUpload style="float:left;margin-right:10px;" mode="basic" [auto]="true" name="image" url="{{HTMLbmxurl}}/imageupload/{{VenueID}}/{{Identifier}}" accept="image/*" maxFileSize="100000" (onUpload)="uploadedFile($event)" (onError)="onErrorFileUpload($event)" chooseLabel="Upload File"></p-fileUpload>
                
                <button *ngIf="FileChosen != null && FileChosen != ''" pButton pRipple type="button" style="float: left;margin-bottom: 10px;margin-top: 1px !important;height: 50px;" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeImage()">
                  <span style="margin-right:10px;" class="pi pi-times"></span>
                  Remove Image
                </button>
  
                <div style="clear:both;width:100%">&nbsp;</div>
  
                <div *ngIf="FileChosen != null && FileChosen != ''">
                <p style="margin-top:10px;">This is how your image will look in place.</p>
  
                <div class="surface-card p-4 shadow-2 border-round packageCard" style="width: 460px;">
                  <div id="image">
                      <img src="{{ FileChosen }}" style="width: 460px;margin-top: -23px;margin-left: -23px;margin-right: -23px;">
                  </div>
                  <div style="font-weight: bold; padding: 20px;font-size:larger;">{{EventObj.name}}</div>
                  <div style="padding-left: 20px; padding-right: 20px" [innerHTML]="">  
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                  </div>
  
                  <div>
                      <br><br>
                  </div>
                  <div>
                      <div>
                          <p-button label="Book this Event" icon="pi pi-check" style="width:420px;">
                          </p-button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
  
              <!--<div class="field mb-4 col-12">
                <label for="EventObjmessage" class="font-medium">Event Message</label>
                <p-editor [(ngModel)]="EventObj.message" [style]="{'height':'320px'}"></p-editor>
              </div>-->
  
              <div class="field mb-4 col-12">
                <label for="Confirmations" class="font-medium">Confirmation</label>
                <p-dropdown [options]="Confirmations" [(ngModel)]="EventObj.confirmation" [(ngModel)]="EventObj.confirmation" placeholder="Please select a confirmation" [showClear]="true" optionLabel="name"></p-dropdown>
              </div>
              <div *ngIf="eventConfirmationValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Confirmation.</div>

              <div class="field mb-4 col-12">
                <label for="packageRule" class="font-medium">Warning</label>
                <p-dropdown [options]="Warnings" [(ngModel)]="EventObj.warning" placeholder="Please select a Warning" [showClear]="true" optionLabel="name"></p-dropdown>
              </div>
  
              <div class="field mb-4 col-12">
                <label for="selectionmode" class="font-medium">Visibility:</label>
                <p-selectButton  id="selectionmode" [options]="stateOptionsVisibility" [(ngModel)]="EventObj.visibility" ></p-selectButton>
              </div>

              <!-- <div class="field mb-4 col-12">
                <label for="selectionmode" class="font-medium">Overall Minumum:</label>
                <p-inputNumber inputId="min" [(ngModel)]="EventObj.min" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
              </div>
  
              <div class="field mb-4 col-12">
                <label for="selectionmode" class="font-medium">Overall Maximum:</label>
                <p-inputNumber inputId="max" [(ngModel)]="EventObj.max" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
              </div>

              <div class="field mb-4 col-12">
                <label for="selectionmode" class="font-medium">Number of Tickets:</label>
                <p-inputNumber inputId="max" [(ngModel)]="EventObj.nooftickets" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
              </div> -->
  
              <div class="field mb-4 col-12">
                <label for="colour" class="font-medium">Colour</label>
                <br />
                <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="EventObj.colour"></p-colorPicker>
                <input style="width:200px;" [(ngModel)]="EventObj.colour" id="hexcolour" type="text" pInputText>
              </div>
  
              <div class="field mb-4 col-12">
                <label for="publiicdepositpolicy" class="font-medium">Public Deposit Policy</label>
                <p-dropdown [options]="DepositPolicies" [(ngModel)]="EventObj.publicdepositpolicy" placeholder="No Deposit Policy" [showClear]="true" optionLabel="name"></p-dropdown>
              </div>
              <div *ngIf="eventPublicDepositValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a public deposit policy.</div>

              <div class="field mb-4 col-12">
                <label for="venuedepositpolicy" class="font-medium">Venue Deposit Policy</label>
                <p-dropdown [options]="DepositPolicies" [(ngModel)]="EventObj.venuedepositpolicy" placeholder="No Deposit Policy" [showClear]="true" optionLabel="name"></p-dropdown>
              </div>
              <div *ngIf="eventVenueDepositValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a venue deposit policy.</div>

              <div class="field mb-4 col-12">
                <label for="questionnaire" class="font-medium">Booking Questionnaire</label>
                <p-dropdown [options]="BookingQuestionnaires" [(ngModel)]="EventObj.questionnaire" placeholder="No Booking Questionnaire" [showClear]="true" optionLabel="name"></p-dropdown>
              </div>

              <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
                <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                  <p-header>
                    <div style="width:100%">
                        <span class="pi pi-calendar" [utc]="true" style="margin-right: 10px;"></span>Booking Date Restriction
                    </div>
                </p-header>

                  <!-- <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left; margin-bottom:20px;"> -->
                    <div class="field mb-4 col-12">
                      <div style="margin-bottom: 10px;">
                        The following settings are copied from the default values in venue settings, However you can change them here by typing in new values.
                      </div>

                      <div class="field mb-4 col-12">
                        <label for="" class="font-medium">Bookings cannot be made within this much time, if the customer chose 3 hours, this would mean if a customer is trying to book for today then they would see time slots in 3 hours time even if you could fit them in sooner.</label>
                        
                        <div style="width:54px; float: left; margin-top:13px;">Days</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesD" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesD" [showButtons]="true" max="365" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                        <div style="width:61px; float: left; margin-top:13px;">Hours</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesH" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesH" [showButtons]="true" max="24" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                        <div style="width:74px; float: left; margin-top:13px;">Minutes</div><div style="float:left; width:190px;"><p-inputNumber id="BookingsCannotBeMadeWithinXMinutesM" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingCannotBeMadeWithinXMinutesM" [showButtons]="true" max="60" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                      </div>
                      
                      <div class="field mb-4 col-12" style="margin-top:20px;">
                        <label style="margin-top:20px;" for="" class="font-medium">Bookings cannot be made after this much time, if you chose 365 days, would mean if a customer is trying to book, they would not see this package if they pick a date which is more than 365 days from now.</label>
                        <div style="width:54px; float: left; margin-top:13px;">Days</div><div style="float:left; width:190px;"><p-inputNumber id="bookingscannotbemadeafterxDays" [style]="{'width': '170px', 'float':'left'}" [(ngModel)]="bookingscannotbemadeafterxDays" [showButtons]="true" max="365" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></div>
                      </div>
                      <div style="clear:both;"></div>
                      <div class="field mb-4 col-12" style="margin-top:20px;">
                        <label for="" class="font-medium">Can bookings be made for today? (If 'no' bookings cannot be made for the current day regardless of the time of day)</label><br>
                        <p-selectButton id="CannotMakeBookingsToday" [(ngModel)] = "EventObj.cannotmakebookingstoday" [style]="{'width': '150px'}" [options]="delayBookingStateOptions" ></p-selectButton>
                      </div>
                    </div>
                  <!-- </div> -->
                  <div style="clear:both;"></div>
                </p-accordionTab>
              </p-accordion>
  
              <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                <div class="field mb-4 col-12">
                  <label for="" class="font-medium">Tags</label>
                  <br />
                  <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="EventObj.tags" display="chip">
                    <ng-template let-value pTemplate="selectedItems">
                      <span *ngFor="let tag of EventObj.tags">
                        <p-chip label="{{tag.name}}" styleClass="mr-2"  [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                          <!--<span class="p-chips-token-icon pi pi-cog" style="margin-right:8px"></span>-->
                        </p-chip>
                      </span>
                      <div *ngIf="!EventObj.tags || EventObj.tags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
  
              <div *ngIf="this.id == '0'">
                <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="NextAds()" style="margin-top: 24px !important; margin-bottom: -25px;"> Next <span class="pi pi-angle-right"></span></button> &nbsp; 
                <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
              </div>
  
            </p-tabPanel>
            <p-tabPanel header="Tickets" leftIcon="pi pi-users">

              <div class="field mb-4 col-12">
                <label for="quantity" class="font-medium">Total overall number of tickets</label>
                <p-inputNumber [(ngModel)]="EventObj.nooftickets" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="1"></p-inputNumber>
              </div>
              <div style="clear:both;"></div>   
              <div class="field mb-4 col-12">
                <label for="quantity" class="font-medium">Add limits to ticket types:</label>
                <p-selectButton id="pticklim" [options]="stateOptions" [(ngModel)]="TicketLimits" [style]="{'width': '300px'}" ></p-selectButton>
              </div>
              <div style="clear:both;"></div>
              <div *ngIf="TicketLimits">
                <div style="clear:both;">&nbsp;</div>
                <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;margin-bottom:20px;padding-bottom:10px;">
                  <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
                    <span class="pi pi-info-circle" style="margin-right:2px;"></span> Define you ticket types and numbers here, you can assign these to your tickets below. You can also define a total number of tickets this limits the total amount of participants at the event.
                  </p>

                  <div class="field mb-4 col-12">
                    <label for="ticketname" class="font-medium">Ticket type name</label>
                    <input [(ngModel)]="TicketType" [ngStyle]="{'border-color': (TicketNameValid==false) ? '#FF0000':''}" id="ticketname" type="text" pInputText>
                  </div>
                  <div *ngIf="!TicketNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Ticket Name.</div>

                  <div class="field mb-4 col-12" style="margin-top:20px">
                    <label for="quantity" class="font-medium">Total number of these ticket types</label>
                    <p-inputNumber [(ngModel)]="TicketNumber" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="1"></p-inputNumber>
                  </div>
                  <div *ngIf="!TicketNumberValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Ticket Number must be greater than 0.</div>

                  <div>
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="AddToTicketTypes()" style="margin-top: 0px !important;float: right;margin-right: 10px;"><span class="pi pi-plus"></span> &nbsp;Add</button>
                  </div>

                  <div style="clear:both;">&nbsp;</div> 

                  <div>
                    <p-table [value]="EventObj.eventtickettypes">
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width:85%">Ticket Type Name</th>
                          <th style="width:10%">Quantity</th>
                          <th style="width:5%">&nbsp;</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-tickettypes>
                        <tr>
                          <td>{{tickettypes.name}}</td>
                          <td>{{tickettypes.quantity}}</td>
                          <td>
                            <button pButton pRipple type="button" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="deleteType(tickettypes)">
                              <span class="pi pi-times"></span>
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
              <div style="clear:both;"></div>
              <div class="field mb-4 col-12">
                <label for="quantity" class="font-medium">Add an early bird discount:</label>
                <p-selectButton id="pearlybird" [options]="stateOptions" [(ngModel)]="EventObj.earlybirddiscount" [style]="{'width': '300px'}" ></p-selectButton>
              </div>
              <div style="clear:both;"></div>
              <div *ngIf="EventObj.earlybirddiscount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">

              <!-- <div class="field mb-4 col-12">
                  <label for="earlybird" class="font-medium">Discount code</label>
                  <input [(ngModel)]="EventObj.discountcode" id="earlybird" type="text" pInputText>
              </div>
              <div *ngIf="!EventEarlybirdDiscountValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Discount Code.</div>
               -->

              <div class="field mb-4 col-12">
                  <label for="venueclosingstartdate">Is this discount to be applied between specific dates or for a number of days before?</label>
                  <p-selectButton [style]="{'width': '450px'}" [options]="stateOptionsRangeType" [(ngModel)]="EventObj.discountrangetype" ></p-selectButton>
              </div>

              <div class="field mb-4 col-12">
                  <label for="venueclosingstartdate">Discount type</label>
                  <p-selectButton [style]="{'width': '450px'}" [options]="stateOptions3" [(ngModel)]="EventObj.discounttype" ></p-selectButton>
              </div>

              <div *ngIf="EventObj.discounttype == 'FIXED'" class="field mb-4 col-12">
                  <label for="eventEarlybirdDiscountAmount" class="font-medium">Discount Amount</label>
                  <p-inputNumber id="eventEarlybirdDiscountAmount" [style]="{'width': '300px'}" mode="currency" currency="{{CurrencySymbol}}" [(ngModel)]="EventObj.discountamount" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
              </div>

              <div *ngIf="EventObj.discounttype == 'PERCENTAGE'" class="field mb-4 col-12">
                  <label for="eventEarlybirdDiscountAmount" class="font-medium">Discount Amount</label>
                  <p-inputNumber id="eventEarlybirdDiscountAmount" [style]="{'width': '300px'}" suffix="%" [(ngModel)]="EventObj.discountamount" [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
              </div>

              <div *ngIf="EventObj.discountrangetype == 0">
                <div class="field mb-4 col-12">
                    <label for="eventEarlybirdDiscountStartDate" class="font-medium">Discount Start Date</label>
                    <p-calendar id="eventEarlybirdDiscountStartDate" [utc]="true" [style]="{'width': '300px'}" firstDayOfWeek="1" [(ngModel)]="tempDiscountStartDate" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventEarlybirdDiscountStartDate"></p-calendar>
                </div>

                <div class="field mb-4 col-12">
                    <label for="eventEarlybirdDiscountEndDate" class="font-medium">Discount End Date</label>
                    <p-calendar id="eventEarlybirdDiscountEndDate" [utc]="true" [style]="{'width': '300px'}" firstDayOfWeek="1" [(ngModel)]="tempDiscountEndDate" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventEarlybirdDiscountEndDate"></p-calendar>
                </div>
              </div>
              <div *ngIf="EventObj.discountrangetype == 1">
                <div class="field mb-4 col-12" style="margin-top:20px">
                  <label for="quantity" class="font-medium">Number of days before event the discount is active for</label>
                  <p-inputNumber [(ngModel)]="EventObj.discountdaysbefore" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="0" max="365"></p-inputNumber>
                </div>
              </div>


              </div>
              <div style="clear:both;"></div>
              <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                <div style="float:left; width:32%">
                  <div class="col-12 md:col-12 drag-column">
                    <h5> Available Tickets </h5>
                    <p-table [value]="availableAdmissions">
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width: 90%;">Name</th>
                          <th>&nbsp;</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-admission>
                        <tr>
                          <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{admission.name}}</td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToAdmissions(admission)">
                              <span class="pi pi-angle-right"></span>
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                    <div *ngIf="availableAdmissions.length == 0" style="padding: 15px;">
                      <i>No admissions available</i>
                    </div>
                  </div>
                </div>
                <div style="float:left; width:65%">
                  <div class="col-12 md:col-12 drop-column" pDroppable="admissions">
                    <h5> Selected Tickets </h5>
                    <p-table [value]="EventObj.admissions">
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-admission let-columns="columns" let-index="rowIndex">
                        <tr [pReorderableRow]="index">
                          <td>
                            <span class="pi pi-bars" pReorderableRowHandle></span>
                          </td>
                          <td>
                            <p-accordion style="background-color: white !important; margin-top: 5px; border: none;">
                              <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;">
                                <p-header>
                                  <div style="width:100%">
                                      <span class="pi pi-user" style="margin-right: 10px;"></span>{{admission.name}} &nbsp;(Min:&nbsp;{{admission.min}},&nbsp;Max:&nbsp;{{admission.max}},&nbsp;Price:&nbsp;{{admission.price | currency:CurrencySymbol}})
                                  </div>
                              </p-header>
      
                                <div class="surface-card p-4 shadow-2 border-round">
                                  <div class="grid formgrid p-fluid">
      
                                    <div class="field mb-4 col-12">
                                      <label for="ResourceStart" class="font-medium">Booking Wizard Text</label>
                                      <input [(ngModel)]="admission.bookingwizardtext" id="classname" type="text" pInputText>
                                    </div>
      
                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Minumum</label>
                                      <p-inputNumber [(ngModel)]="admission.min" [showButtons]="true" min="0" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                    </div>
      
                                    <div class="field mb-4 col-12">
                                      <label for="ResourceOpt" class="font-medium">Maximum</label>
                                      <p-inputNumber [(ngModel)]="admission.max" [showButtons]="true" min="1" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                    </div>

                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Price:</label>
                                      <p-inputNumber  [(ngModel)]="admission.price" [showButtons]="true" buttonLayout="horizontal" mode="currency" currency="{{CurrencySymbol}}"></p-inputNumber>
                                    </div>

                                    <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
                                      <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                                        <p-header>
                                          <div style="width:100%">
                                              <span class="pi pi-cog" style="margin-right: 10px;"></span>Advanced
                                          </div>
                                        </p-header>
                                        <div class="field mb-4 col-12">
                                            <label for="quantity" class="font-medium">Participant:</label>
                                            <p-selectButton id="ppar" [options]="stateOptionsPart" [(ngModel)]="admission.participant" ></p-selectButton>
                                        </div>

                                        <div class="field mb-4 col-12">
                                          <label for="QuantityMode" class="font-medium">Must Include:</label>
                                          <p-selectButton id="pmi"  [options]="stateOptionsMI" [(ngModel)]="admission.mustinclude" ></p-selectButton>
                                        </div>
                                          
                                        <div *ngIf="TicketLimits && admission.participant" class="field mb-4 col-12">
                                          <label for="questionnaire" class="font-medium">Ticket Type</label>
                                          <p-dropdown [options]="EventObj.eventtickettypes" [(ngModel)]="admission.eventtickettype" placeholder="Select a Ticket Ttpe" [showClear]="true" optionLabel="name"></p-dropdown>
                                        </div>
      
                                        <div class="field mb-4 col-12">
                                          <label for="quantity" class="font-medium">Fixed Price:</label>
                                          <p-selectButton id="ppp" [options]="stateOptionsPPP" [(ngModel)]="admission.fixedPrice" ></p-selectButton>
                                        </div>

                                        <div class="field mb-4 col-12">
                                          <label for="quantity" class="font-medium">Do you want to link any admissions?:</label>
                                          <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.uselinkedadmissions" ></p-selectButton>
                                        </div>
    
                                        <div *ngIf="admission.uselinkedadmissions" style="margin-bottom:20px;" class="surface-card p-4 shadow-2 border-round">
                                              
                                          <div  *ngIf="admission.uselinkedadmissions"  class="field mb-4 col-12" style="margin-top:20px;">
                                            <label for="quantity" class="font-medium">Would you like to only set the maximum of this admission based on the linked admissions:</label>
                                            <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.onlysetmax" ></p-selectButton>
                                          </div>
    
                                          <div style="width:100%;">
                                            <div style="float:left; width:87%;">
                                              <p-dropdown [options]="EventObj.admissions" [(ngModel)]="CurrentSelectedLinkedAdmission" placeholder="Please select as Admission" [showClear]="true" optionLabel="name"></p-dropdown>
                                            </div>
                                            <div style="float:left; width:3%;">
                                              &nbsp;
                                            </div>
                                            <div style="float:left; width:10%;">
                                              <button pButton pRipple type="button" style="margin-bottom: 10px;margin-top: 0px !important;height: 50px;" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="addAdmissionToLinked(admission)">
                                                Add
                                              </button>
                                            </div>
                                          </div>
    
                                          <div style="clear:both;"></div>
    
                                          <div>
                                            <p-table [value]="admission.linkedadmissions">
                                              <ng-template pTemplate="header">
                                                <tr>
                                                  <th style="width: 95%;">Name</th>
                                                  <!-- <th style="width: 20%;">Threshold</th> -->
                                                  <th style="width: 5%;">&nbsp;</th>
                                                </tr>
                                              </ng-template>
                                              <ng-template pTemplate="body" let-linkedadmissions>
                                                <tr>
                                                  <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{linkedadmissions.admissionname}}</td>
                                                  <!-- <td>
                                                    <p-inputNumber min="1" max="10000"  [(ngModel)]="linkedadmissions.threshold" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                                  </td> -->
                                                  <td>
                                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeLinkedAdmission(linkedadmissions)">
                                                      <span class="pi pi-trash"></span>
                                                    </button>
                                                  </td>
                                                </tr>
                                              </ng-template>
                                            </p-table>
                                          </div>
                                        </div>
    
                                        <div class="field mb-4 col-12">
                                          <label for="quantity" class="font-medium">Do you want to link any admissions for a discount?:</label>
                                          <p-selectButton id="ppp" [options]="stateOptions" [(ngModel)]="admission.uselinkeddiscounts" ></p-selectButton>
                                        </div>
    
                                        <div *ngIf="admission.uselinkeddiscounts" class="surface-card p-4 shadow-2 border-round">
                                          <div *ngIf="admission.uselinkeddiscounts" style="width:100%;">
                                            <div style="float:left; width:87%;">
                                              <p-dropdown [options]="EventObj.admissions" [(ngModel)]="CurrentSelectedAdmission" placeholder="Please select as Admission" [showClear]="true" optionLabel="name"></p-dropdown>
                                            </div>
                                            <div style="float:left; width:3%;">
                                              &nbsp;
                                            </div>
                                            <div style="float:left; width:10%;">
                                              <button pButton pRipple type="button" style="margin-bottom: 10px;margin-top: 0px !important;height: 50px;" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="addAdmissionToLinkedDiscount(admission)">
                                                Add
                                              </button>
                                            </div>
                                          </div>
    
                                          <div style="clear:both;"></div>
    
                                          <div *ngIf="admission.uselinkeddiscounts" >
                                            <p-table [value]="admission.linkeddiscounts">
                                              <ng-template pTemplate="header">
                                                <tr>
                                                  <th style="width: 55%;">Name</th>
                                                  <th style="width: 2%;">Discount</th>
                                                  <th style="width: 20%;">Threshold</th>
                                                  <th style="width: 5%;">&nbsp;</th>
                                                </tr>
                                              </ng-template>
                                              <ng-template pTemplate="body" let-linkeddiscounts>
                                                <tr>
                                                  <td><span class="pi pi-user" style="margin-right: 10px;"></span>{{linkeddiscounts.admissionname}}</td>
                                                  <td>
                                                    <p-inputNumber min="1" max="100" [(ngModel)]="linkeddiscounts.percentagediscount" suffix="%" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                                  </td>
                                                  <td>
                                                    <p-inputNumber min="1" max="10000"  [(ngModel)]="linkeddiscounts.threshold" [style]="{width: '162px'}" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                                  </td>
                                                  <td>
                                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeLinkedDiscountAdmission(linkeddiscounts)">
                                                      <span class="pi pi-trash"></span>
                                                    </button>
                                                  </td>
                                                </tr>
                                              </ng-template>
                                            </p-table>
    
                                            <!-- <div class="field mb-4 col-12" style="margin-top:20px;">
                                              <label for="quantity" class="font-medium">Maximum number of linked discounts:</label>
                                              <p-inputNumber  [(ngModel)]="admission.maxlinkeddiscounts" [showButtons]="true" buttonLayout="horizontal" mode="decimal" min="1" max="100"></p-inputNumber>
                                            </div> -->
                                          </div>
                                        </div>

                                      </p-accordionTab>
                                    </p-accordion>
      
                                  </div>
                                </div>
                              </p-accordionTab>
                            </p-accordion>
                          </td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '10px'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromAdmissions(admission)">
                              <span class="pi pi-angle-left"></span>
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                    <!--<div *ngIf="selectedAdmissions.length == 0" style="padding: 15px;">
                      <i>No admissions selected, drag some here.</i>
                    </div>-->
                  </div>
                </div>
              </div>
  
              <div *ngIf="this.id == '0'">
                <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="NextDates()" style="margin-top: 24px !important; margin-bottom: -25px;"> Next <span class="pi pi-angle-right"></span></button> &nbsp; 
                <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
              </div>
  
            </p-tabPanel> 
            <p-tabPanel header="Dates" leftIcon="pi pi-calendar">
              

              <div class="surface-card p-4 shadow-2 border-round">
                <label for="EventDetails" class="font-medium">When will this Event run?</label>
                <br /><br />
                <div class="field mb-4 col-12">
                  <div style="float:left; width:310px;">
                    <label for="eventstarttime">Starting on</label>
                    &nbsp;&nbsp;<p-calendar [(ngModel)]="EventStartDate" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventenddate" style="width: 170px !important;display: inline-block;" (onSelect)="setEndDate()"></p-calendar>&nbsp;&nbsp;at&nbsp;&nbsp;                  
                  </div>
                  <p-dropdown [options]="eventStartTimeHours" [style]="{'width': '100px', 'float': 'left'}"  [(ngModel)]="eventSelectedStartTimeHours" optionValue="code" optionLabel="name" (onChange)="setEndDateLabel()"></p-dropdown>
                  <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                  <p-dropdown [options]="eventStartTimeMins" [style]="{'width': '100px', 'float': 'left'}"  [(ngModel)]="eventSelectedStartTimeMins" optionValue="code" optionLabel="name" (onChange)="setEndDateLabel()"></p-dropdown>&nbsp;&nbsp;
                  <div style="float:left; width:450px;">
                    &nbsp;&nbsp;for&nbsp;&nbsp;<p-inputNumber [(ngModel)]="EventObj.eventlength" [showButtons]="true" buttonLayout="horizontal" min="5" max="1440" mode="decimal" style="width:170px;" (onBlur)="setEndDateLabel()" (onInput)="setEndDateLabel()"></p-inputNumber>&nbsp;&nbsp;minutes &nbsp;<span style="font-weight:bold;font-size:16px">({{CalculatedEndTime | date:'dd/MM/yyyy HH:mm'}})</span>
                  </div>
                  <div *ngIf="!EventRepeat" style="float:left; width:300px;">
                    <button style="margin-top: 0px !important; margin-left:10px; width: 100px !important;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="createDates();"><span class="pi pi-plus" style="margin-right:8px;"></span>Add</button>
                    <button style="margin-top: 0px !important; margin-left:10px; width: 164px !important;" pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="clearDates();"><span class="pi pi-trash" style="margin-right:8px;"></span>Clear Dates</button>
                  </div>

                </div>

                <div style="clear:both;">&nbsp;</div>
                <div class="field mb-4 col-12">
                  <label for="prepeat" class="font-medium">Does this event repeat?:</label>
                  <p-selectButton id="prepeat" [options]="stateOptions" [(ngModel)]="EventRepeat" [style]="{'width': '300px'}" ></p-selectButton>
                </div>

                <div style="clear:both;">&nbsp;</div>

                <div  class="surface-card p-4 shadow-2 border-round" *ngIf="EventRepeat">
                  <div style="float:left;"><label>Please select the approprite options below.</label></div>
                  <div style="clear:both;">&nbsp;</div>
                  <div class="field mb-4 col-12" style="float: left;width: 117px;margin-top: 12px;margin-bottom: -12px !important;">
                    Repeat every
                  </div>
                  <div style="float:left;width: 166px;">
                    <p-dropdown [options]="RepeatOptionInterval" [(ngModel)]="ChosenRepeatOptionInterval" optionLabel="name" [style]="{'width': '157px'}"></p-dropdown>
                  </div>
                  <div *ngIf="ChosenRepeatOptionInterval.code=='No Repeat'">
                    <button style="margin-top: 0px !important; margin-left:10px; width: 100px !important;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="createDates();"><span class="pi pi-plus" style="margin-right:8px;"></span>Add</button>
                    <button style="margin-top: 0px !important; margin-left:10px; width: 164px !important;" pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="clearDates();"><span class="pi pi-trash" style="margin-right:8px;"></span>Clear Dates</button>
                
                  </div>
                  <div *ngIf="ChosenRepeatOptionInterval.code!='No Repeat'" style="width: 160px;float: left;">
                    <p-dropdown [options]="RepeatOptionPeriod" [(ngModel)]="ChosenRepeatOptionPeriod" [showClear]="false" [style]="{'width': '150px'}" optionLabel="name"></p-dropdown>
                  </div> 

                  <div *ngIf="ChosenRepeatOptionInterval.code!='No Repeat'" style="float:left; width:675px;">
                    <div style="float: left;width: 31px;margin-top: 12px;">until</div>

                    <div *ngIf="ChosenRepeatOptionPeriod.code!='Hour(s)'" style="float:left; width:464px;">
                      &nbsp;&nbsp;<p-calendar [(ngModel)]="EventEndDate" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="eventenddate" style="width: 170px !important;display: inline-block;"></p-calendar>
                    
                      <button style="margin-top: 0px !important; margin-left:10px; width: 100px !important;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="createDates();"><span class="pi pi-plus" style="margin-right:8px;"></span>Add</button>
                      <button style="margin-top: 0px !important; margin-left:10px; width: 164px !important;" pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="clearDates();"><span class="pi pi-trash" style="margin-right:8px;"></span>Clear Dates</button>
                
                    </div>

                    <div *ngIf="ChosenRepeatOptionPeriod.code=='Hour(s)'" style="float:left; width:644px;">
                      <label style="float:left; margin-top:12px; margin-left:10px; margin-right:10px;font-weight:bold;" for="eventstarttime">{{EventStartDate | date:'dd/MM/yyyy'}}</label>
                      <p-dropdown [options]="eventStartTimeHours" [style]="{'width': '100px', 'float': 'left'}"  [(ngModel)]="eventSelectedEndTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="eventStartTimeMins" [style]="{'width': '100px', 'float': 'left'}"  [(ngModel)]="eventSelectedEndTimeMins" optionValue="code" optionLabel="name"></p-dropdown>&nbsp;&nbsp;
                    
                      <button style="margin-top: 0px !important; margin-left:10px; width: 100px !important;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="createDates();"><span class="pi pi-plus" style="margin-right:8px;"></span>Add</button>
                      <button style="margin-top: 0px !important; margin-left:10px; width: 164px !important;" pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="clearDates();"><span class="pi pi-trash" style="margin-right:8px;"></span>Clear Dates</button>
                
                    </div>
                    
                  </div>

                  <div *ngIf="ChosenRepeatOptionPeriod != null && ChosenRepeatOptionPeriod.code=='Week(s)'">
                    <div style="clear:both;">&nbsp;</div>
                    <div>which days of the week would you like this to run on?</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventMonday"></p-inputSwitch></div><div style="width:300px;float:left;">Monday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventTuesday"></p-inputSwitch></div><div style="width:300px;float:left;">Tuesday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventWednesday"></p-inputSwitch></div><div style="width:300px;float:left;">Wednesday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventThursday"></p-inputSwitch></div><div style="width:300px;float:left;">Thursday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventFriday"></p-inputSwitch></div><div style="width:300px;float:left;">Friday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventSaturday"></p-inputSwitch></div><div style="width:300px;float:left;">Saturday</div>
                    <div style="clear:both;">&nbsp;</div>
                    <div style="width:60px;float:left;"><p-inputSwitch [(ngModel)]="EventSunday"></p-inputSwitch></div><div style="width:300px;float:left;">Sunday</div>
                    <div style="clear:both;">&nbsp;</div>
                  </div>

                  <div style="clear:both;"></div>
                </div>
                <div style="clear:both;">&nbsp;</div>
                <div>

                  <div *ngIf="DateError" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your end date must be greater than your start date.</div>

                  <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                    <div style="float:left; width:49%">
                      <div class="drag-column">
                        <h5> Chosen Dates </h5>
                        <p-table [value]="EventObj.eventdates">
                          <ng-template pTemplate="header">
                            <tr style="margin-bottom:10px;">
                              <th style="width:100%; margin-bottom:5px; display:block">Dates</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-dates>
                            <p-accordion style="background-color: white !important; margin-top: 5px; border: none;">
                              <p-accordionTab style="background-color: white !important; margin-top: 10px; border: none;">
                                <p-header style="width:100%;margin-bottom:5px !important">
                                  <div style="width:100%; margin-top: 14px;">

                                      <!--<span *ngIf="this.datePipe.transform(date.startdatetime, 'yyyy-MM-dd') == this.datePipe.transform(date.enddatetime, 'yyyy-MM-dd')" class="pi pi-calendar" style="margin-right: 10px;"></span>{{dates.startdatetime | date:'dd/MM/yyyy HH:mm'}} &nbsp; - &nbsp; {{dates.enddatetime | date:'HH:mm'}} &nbsp; ({{dates.nooftickets}} tickets)
                                      
                                      <span *ngIf="this.datePipe.transform(date.startdatetime, 'yyyy-MM-dd') != this.datePipe.transform(date.enddatetime, 'yyyy-MM-dd')" class="pi pi-calendar" style="margin-right: 10px;"></span>{{dates.startdatetime | date:'dd/MM/yyyy HH:mm'}} &nbsp; - &nbsp; {{dates.enddatetime | date:'dd/MM/yyyy HH:mm'}} &nbsp; ({{dates.nooftickets}} tickets)
                                       -->

                                      <span  class="pi pi-calendar" style="margin-right: 10px;"></span>{{dates.startdatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}} &nbsp; - &nbsp; {{dates.enddatetime | date:'HH:mm' : 'UTC'}} &nbsp; ({{dates.nooftickets}} tickets)
                                      
                                      <span style="float:right;">
                                        <button pButton pRipple type="button" class="p-button-sm p-element p-button-danger w-auto mt-3" style="margin-top: -11px !important;" (click)="AddToExcluded(dates)">
                                          <span class="pi pi-times"></span>
                                        </button>
                                      </span>
                                  </div>
                              </p-header>
      
                                <div class="surface-card p-4 shadow-2 border-round">
                                  <div class="grid formgrid p-fluid">
      
                                    <div class="field mb-4 col-12">
                                      <label for="quantity" class="font-medium">Number of tickets</label>
                                      <p-inputNumber [(ngModel)]="dates.nooftickets" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                    </div>

                                    <div class="field mb-4 col-12"  *ngFor="let tt of dates.eventtickettypes">
                                      <label for="quantity" class="font-medium">{{tt.name}}</label>
                                      <p-inputNumber [(ngModel)]="tt.quantity" [showButtons]="true" buttonLayout="horizontal" mode="decimal"></p-inputNumber>
                                    </div>
      
                                  </div>
                                </div>
                              </p-accordionTab>
                            </p-accordion>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                    <div style="float:left; width:49%">
                      <div class="col-12 md:col-12 drop-column" pDroppable="resources">
                        <h5> Excluded dates</h5>
                        <p-table [value]="EventObj.eventdateexceptions">
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="width:42%">Start Date/Time</th>
                              <th style="width:43%">End Date/Time</th>
                              <th style="width:5%">&nbsp;</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-dates>
                            <tr>
                              <td><span class="pi pi-calendar" style="margin-right: 10px;"></span>{{dates.startdatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</td>
                              <td><span class="pi pi-calendar" style="margin-right: 10px;"></span>{{dates.enddatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</td>
                               <td>
                                <button pButton pRipple type="button" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromExcluded(dates)">
                                  <span class="pi pi-chevron-left"></span>
                                </button>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="clear:both;">&nbsp;</div>
              </div>

              <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="NextRes()" style="margin-top: 24px !important; margin-bottom: -25px;"> Next <span class="pi pi-angle-right"></span></button> &nbsp; 
              <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>

            </p-tabPanel>
            <p-tabPanel header="Resources" leftIcon="pi pi-clone">
  
              <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                <div style="float:left; width:32%">
                  <div class="drag-column">
                    <h5> Available Resources </h5>
                    <p-table [value]="availableResources">
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width:90%">Name</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-resource>
                        <tr>
                          <td *ngIf="!resource.isresourcecontainer"><span class="pi pi-stop" style="margin-right: 10px;"></span>{{resource.name}}</td>
                          <td *ngIf="resource.isresourcecontainer && !resource.isconstructed"><span class="pi pi-clone" style="margin-right: 10px;"></span>{{resource.name}}</td>
                          <td *ngIf="resource.isresourcecontainer && resource.isconstructed"><span class="pi pi-sitemap" style="margin-right: 10px;"></span>{{resource.name}}</td>
                          <td>&nbsp;</td>
                          <td>
                            <button pButton pRipple type="button" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToResources(resource)">
                              <span class="pi pi-angle-right"></span>
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                    <!--<div *ngIf="availableResources.length == 0" style="padding: 15px;">
                      <i>No resources available</i>
                    </div>-->
                  </div>
                </div>
                <div style="float:left; width:65%">
                  <div class="col-12 md:col-12 drop-column" pDroppable="resources">
                    <h5> Selected Resources </h5>
                    <p-table [value]="EventObj.resources">
                      <ng-template pTemplate="body" let-resource let-columns="columns" let-index="rowIndex">
                        <tr [pReorderableRow]="index">
                        <td>
                          <span class="pi pi-bars" pReorderableRowHandle></span>
                          
                        </td>
                        <td>
                          <p-accordion style="background-color: white !important; margin-top: 5px; border: none;">
                          <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;">
                            <p-header>
                              <div *ngIf="!resource.isresourcecontainer" style="width:100%">
                                  <span class="pi pi-stop" style="margin-right: 10px;"></span>{{resource.name}}&nbsp;(Set-up:{{resource.minutesbefore}},&nbsp;Clean-up:{{resource.minutesafter}})
                              </div>
                      
                              <div *ngIf="resource.isresourcecontainer && !resource.isconstructed" style="width:100%">
                                  <span class="pi pi-clone" style="margin-right: 10px;"></span>{{resource.name}}&nbsp;(Set-up:{{resource.minutesbefore}},&nbsp;Clean-up:{{resource.minutesafter}})
                              </div>
  
                              <div *ngIf="resource.isresourcecontainer && resource.isconstructed" style="width:100%">
                                <span class="pi pi-sitemap" style="margin-right: 10px;"></span>{{resource.name}}&nbsp;(Set-up:{{resource.minutesbefore}},&nbsp;Clean-up:{{resource.minutesafter}})
                            </div>
                          </p-header>
  
                            <div class="surface-card p-4 shadow-2 border-round">
                              <div class="grid formgrid p-fluid">
  
                                  <div class="field mb-4 col-12">
                                    <label for="selectionmode" class="font-medium">Set-up Time:</label>
                                    <p-inputNumber inputId="rlen{{index}}" [(ngModel)]="EventObj.resources[index].minutesbefore" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
                                  </div>

                                  <div class="field mb-4 col-12">
                                    <label for="selectionmode" class="font-medium">Clean-up Time:</label>
                                    <p-inputNumber inputId="rlen{{index}}" [(ngModel)]="EventObj.resources[index].minutesafter" [showButtons]="true" mode="decimal" buttonLayout="horizontal"></p-inputNumber>
                                  </div>
                                
                              </div>
                            </div>
                          </p-accordionTab>
                        </p-accordion>
                        </td>
                        <td>
                          
                          <button pButton pRipple type="button" [style]="{'margin-bottom': '10px'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromResources(resource)">
                            <span class="pi pi-angle-left"></span>
                          </button>
                        </td>
                      </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
  
              <div *ngIf="this.id == '0'">
                <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData()" style="margin-top: 24px !important; margin-bottom: -25px;"> Save Changes <span class="pi pi-angle-right"></span></button> &nbsp; 
                <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()" style="margin-top: 24px !important; margin-bottom: -25px;"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
              </div>
  
            </p-tabPanel>
            </p-tabView>
        </div>
      </div>
      <div class="col-12" *ngIf="this.id != '0'">
        <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();"><span class="pi pi-pencil" style="margin-right:8px;"></span> Save Changes </button> &nbsp; 
        <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()"> <span class="pi pi-times" style="margin-right:8px;"></span> Cancel </button>
      </div>
    </div>
    <style>
      :host ::ng-deep .p-chip.custom-chip {
        background: var(--primary-color);
        color: var(--primary-color-text);
      }
    </style>