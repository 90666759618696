import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { cResourceclosings, resourceclosings } from 'src/types/resourceclosings';
import { resourceclosings_returndata } from 'src/types/resourceclosings_returndata';

import { users_returndata } from 'src/types/user_returndata';
import { resource_returndata } from 'src/types/resource_returndata';
import { cResource } from 'src/types/resource';

@Component({
  selector: 'app-resourceclosingsedit',
  templateUrl: './resourceclosingsedit.component.html',
  styleUrls: ['./resourceclosingsedit.component.css']
})
export class ResourceclosingseditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  ResourceClosing: any;
  ResourceList: any[];
  httpResponse: any;
  id: string;
  vcstartdate: Date;
  vcenddate: Date;

  resourceNameValid: boolean = true;
  resourceStartDateValid: boolean = true;
  resourceEndDateValid: boolean = true;
  resourceDateLoginValid: boolean = true;

  resourceStartTimeHours: any[];
  resourceStartTimeMins: any[];

  resourceEndTimeHours: any[];
  resourceEndTimeMins: any[];
  
  resourceSelectedStartTimeHours: string;
  resourceSelectedStartTimeMins: string;

  resourceSelectedEndTimeHours: string;
  resourceSelectedEndTimeMins: string;
  
  pageReady: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  chosenResource: cResource = new cResource();

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.ResourceClosing = {
      id: null,
      resourceclosingid: null,
      name: "",
      resource: null,
      disabled: false,
      venueid: this.VenueID,
      startdate: null,
      enddate: null,
      publicmessage: null,
      venuemessage: null
    }


    this.resourceStartTimeHours = [
      {name: '00', code: '00'},
      {name: '01', code: '01'},
      {name: '02', code: '02'},
      {name: '03', code: '03'},
      {name: '04', code: '04'},
      {name: '05', code: '05'},
      {name: '06', code: '06'},
      {name: '07', code: '07'},
      {name: '08', code: '08'},
      {name: '09', code: '09'},
      {name: '10', code: '10'},
      {name: '11', code: '11'},
      {name: '12', code: '12'},
      {name: '13', code: '13'},
      {name: '14', code: '14'},
      {name: '15', code: '15'},
      {name: '16', code: '16'},
      {name: '17', code: '17'},
      {name: '18', code: '18'},
      {name: '19', code: '19'},
      {name: '20', code: '20'},
      {name: '21', code: '21'},
      {name: '22', code: '22'},
      {name: '23', code: '23'}
      ];
   
    this.resourceStartTimeMins = [
        {name: '00', code: '00'},
        {name: '05', code: '05'},
        {name: '10', code: '10'},
        {name: '15', code: '15'},
        {name: '20', code: '20'},
        {name: '25', code: '25'},
        {name: '30', code: '30'},
        {name: '35', code: '35'},
        {name: '40', code: '40'},
        {name: '45', code: '45'},
        {name: '50', code: '50'},
        {name: '55', code: '55'},
      ];

  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        //this.getResources();
        let user = this.httpResponse.data;
        if(getAccess("RESOURCECLOSINGS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          this.http.get(bmxurl + "resourcelist/" + this.VenueID).subscribe(response => {
            this.ResourceList = (response as any).data;
            
            if(this.id != "0")
            {
              this.http.get(bmxurl + "resourceClosing/" + this.VenueID + "/" + this.id).subscribe(response => {
                this.httpResponse = (response as resourceclosings_returndata)
                if (this.httpResponse.succeeded)
                {
                  this.ResourceClosing = new cResourceclosings();
                  this.ResourceClosing.id = (this.httpResponse.data as cResourceclosings).id;
                  this.ResourceClosing.resourceclosingid = (this.httpResponse.data as cResourceclosings).resourceclosingid;
                  this.ResourceClosing.name = (this.httpResponse.data as cResourceclosings).name;
                  this.ResourceClosing.resourcetoclose = (this.httpResponse.data as cResourceclosings).resourcetoclose;
                  this.ResourceClosing.disabled = (this.httpResponse.data as cResourceclosings).disabled;
                  this.ResourceClosing.venueid = (this.httpResponse.data as cResourceclosings).venueid;

                  if (this.httpResponse.data.startdate) {
                    this.ResourceClosing.startdate = new Date((this.httpResponse.data as cResourceclosings).startdate);
                  }
                  if (this.httpResponse.data.enddate) {
                    this.ResourceClosing.enddate = new Date((this.httpResponse.data as cResourceclosings).enddate);
                  }
                  this.ResourceClosing.publicmessage = (this.httpResponse.data as cResourceclosings).publicmessage;
                  this.ResourceClosing.venuemessage = (this.httpResponse.data as cResourceclosings).venuemessage;
        
                  this.resourceSelectedStartTimeHours = this.ResourceClosing.startdate.toTimeString().substring(0,2);
                  this.resourceSelectedStartTimeMins = this.ResourceClosing.startdate.toTimeString().substring(3);
          
                  this.resourceSelectedEndTimeHours = this.ResourceClosing.enddate.toTimeString().substring(0,2);
                  this.resourceSelectedEndTimeMins = this.ResourceClosing.enddate.toTimeString().substring(3);
      
                  this.getChosenResource();
                  
                  this.pageReady = true;
                }
                else
                {
                  this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource Closings'});
                  this.pageReady = false;
                }
              }, error => {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource Closings, error:' + error.messages[0]});
                this.pageReady = false;
              });
            }
            else
            {
              this.pageReady = true;
            }
          
        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Reseources, error:' + error.messages[0]});
        });

        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  getChosenResource()
  {
    this.ResourceList.forEach(element => {
      if(element.id == this.ResourceClosing.resourcetoclose.id)
      {
        this.chosenResource = element;
      }                                   
    });
  }

  // getResources(){
  //   this.http.get(bmxurl + "resourcelist/" + this.VenueID).subscribe(response => {
  //     this.ResourceList = (response as any).data;

  //     this.getChosenResource();
  //   }, error => {
  //     this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Reseources, error:' + error.messages[0]});
  //   });
    
  // }

  updateData()
  {
    if ([this.DoDateValidCheck(), this.DoValidCheck(), this.DoDateCheck()].every(x => x == true))
    {
      this.ResourceClosing.resourcetoclose = this.chosenResource;
      
      if(this.id=="0")
      {
        this.http.post<resourceclosings>(bmxurl + "/ResourceClosing", this.ResourceClosing).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Resource Closing successfully created'});
            this.router.navigateByUrl("/resourceclosings");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })
      }
      else
      {
        this.ResourceClosing.disabled = false;
        
        this.http.put<resourceclosings>(bmxurl + "/ResourceClosing", this.ResourceClosing).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Resource Closing successfully updated'});
            this.router.navigateByUrl("/resourceclosings");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoDateCheck()
  {
    let AllValid = true;

    this.ResourceClosing.startdate.setHours(Number(this.resourceSelectedStartTimeHours), Number(this.resourceSelectedStartTimeMins));
    this.ResourceClosing.enddate.setHours(Number(this.resourceSelectedEndTimeHours), Number(this.resourceSelectedEndTimeMins));

    if(this.ResourceClosing.startdate >= this.ResourceClosing.enddate)
    {
      AllValid = false;
      this.resourceDateLoginValid = false;
    }
    else
    {
      this.resourceDateLoginValid = true;
    }

    return AllValid
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.ResourceClosing.name == null || this.ResourceClosing.name == "")
    {
      AllValid = false;
      this.resourceNameValid = false;
    }
    else
    {
      this.resourceNameValid = true;
    }

    return AllValid;
  }

  DoDateValidCheck()
  {
    let AllValid = true;

    if(this.ResourceClosing.startdate == null)
    {
      AllValid = false;
      this.resourceStartDateValid = false;
    }
    else
    {
      this.resourceStartDateValid = true;
    }

    if(this.ResourceClosing.enddate == null)
    {
      AllValid = false;
      this.resourceEndDateValid = false;
    }
    else
    {
      this.resourceEndDateValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/resourceclosings");
  }
}
