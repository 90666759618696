<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade" [fullScreen] = "true"><p style="color: black" > loading... </p></ngx-spinner>

<p-dialog header="Email Warning" [(visible)]="showEmailWarning" [style]="{width: '30vw'}" [baseZIndex]="10000"  [modal]="true">
    <div class="field mb-4 col-12" style="text-align: center;">
      <br>
        <p style="color: red; font-weight: bold;">You have {{EmailsRemaining}} emails remaining, please use the button below purchase some more</p>
        <br>
        <p style="font-size: 12px;">Please note if you run out of emails, you will no longer be able to send Task and Marketing emails (Confirmations and Booking Records will continue to be sent)</p>
    </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-shopping-bag" label="Buy more Emails" styleClass="p-button p-button-success" (click)="buyMoreEmails()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="SMS Warning" [(visible)]="showSMSWarning" [style]="{width: '30vw'}" [baseZIndex]="10000"  [modal]="true">
  <div class="field mb-4 col-12" style="text-align: center;">
    <br>
      <p style="color: red; font-weight: bold;">You have {{SMSsRemaining}} SMSs remaining, please use the button below purchase some more</p>
      <br>
      <p style="font-size: 12px;">Please note if you run out of SMSs, you will no longer be able to send Confirmation, Task and Marketing SMSs</p>
  </div>
<ng-template pTemplate="footer">
  <p-button icon="pi pi-shopping-bag" label="Buy more SMSs" styleClass="p-button p-button-success" (click)="buyMoreSMSs()"></p-button>
</ng-template>
</p-dialog>

<p-dialog header="Change PIN" [(visible)]="showPasswordChange" [style]="{width: '30vw'}" [baseZIndex]="10000"  [modal]="true">
  <div class="surface-card p-4 shadow-2 border-round" style="margin-top:5px;">
  <div class="field mb-4 col-12">
      <p>Please change your PIN</p>
      Old PIN
      <input pKeyFilter="int" pInputText type="text" maxlength="4" type="password" style="font-size: 36px;width:100%;text-align: center;" [(ngModel)]="OldPIN"/><br><br>
      New PIN
      <input pKeyFilter="int" pInputText type="text" maxlength="4" type="password" style="font-size: 36px;width:100%;text-align: center;" [(ngModel)]="NewPIN"/><br><br>
      Repeat New PIN
      <input pKeyFilter="int" pInputText type="text" maxlength="4" type="password" style="font-size: 36px;width:100%;text-align: center;" [(ngModel)]="RepeatNewPIN"/>
  </div>
  <div *ngIf="!PasswordsMatch" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your PINs do not match.</div>
</div>
<ng-template pTemplate="footer">
  <p-button icon="pi pi-check" label="Change PIN" styleClass="p-button p-button-success" (click)="doPINChange()"></p-button>
</ng-template>

</p-dialog>

<p-toast position="top-center"></p-toast>

<div *ngIf="!prodmode" style="background-color: red; color: white;text-align: center;padding: 10px; font-weight: bold; font-size: 20px;">
  --- DEV MODE ---
</div>

<div *ngIf="ShowTimeOut" style="background-color: #6B798D; color: white;text-align: center;padding: 10px; font-weight: bold; font-size: 20px;">
  Your sessions is idle, you will be logged out in {{countdown}} seconds.
</div>
<div *ngIf="auth.isAuthenticated$ | async; else loggedOut" class="min-h-screen flex surface-ground">
    <!-- <div *ngIf="auth.isAuthenticated$ | async;" id="app-sidebar" class="surface-section h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 surface-border select-none" style="width:280px">
        <div class="flex flex-column h-full">
            <div class="flex align-items-center px-5 flex-shrink-0" style="height:60px; margin-top:20px;">
                <img src="assets/images/wannabook.png" alt="Image" height="60">
            </div>

            <div *ngIf="ShowBC" style="text-align: right; margin-bottom: 20px;margin-left:20px;">
                <span class="p-input-icon-left ml-auto" style="margin-right:20px;"> 
                    <input pInputText type="text" placeholder="Search Bookings" style="height: 40px; padding-left:33px;width: 240px; margin-right: 10px;" [(ngModel)]="searchCriteria" />
                </span>
                <button pButton type="button" style="height: 40px;width: 40px;margin-right: 19px;margin-top: -40px;float: right;" class="p-button-sm p-element p-ripple p-button-outlined p-component" (click)="getSearchResults()">
                  <span class="pi pi-search" style="margin-left:-6px;"></span>
                  </button>
            </div>

            <div class="overflow-y-auto">
                <p-menu [model]="items"></p-menu>
                
            </div>
        </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
        <div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style="height:60px">
            <div class="flex">
               <span style="font-size:28px; font-weight: lighter;;">Welcome:&nbsp;{{vsVenueName}}</span>
            </div>
            <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" enterActiveClass="fadein" leaveToClass="hidden" leaveActiveClass="fadeout">
                <i class="pi pi-ellipsis-v text-2xl"></i>
            </a>
            <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row 
                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                
                <li>
                  <a *ngIf="!businessOwner" (click)="backToPin()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                      transition-duration-150 transition-colors">
                      <i class="pi pi-th-large text-base lg:text-2xl mr-2 lg:mr-0"></i>
                      <span class="block lg:hidden font-medium">Return to Pin Pad</span>
                  </a>
                </li>

                <li>
                  <a *ngIf="!businessOwner && ShowChangePassword" (click)="manageUserPassword()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                      transition-duration-150 transition-colors">
                      <i class="pi pi-unlock text-base lg:text-2xl mr-2 lg:mr-0"></i>
                      <span class="block lg:hidden font-medium">Manage Password</span>
                  </a>
                </li>

                <li>
                  <a *ngIf="businessOwner" (click)="backToVenues()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                      transition-duration-150 transition-colors">
                      <i class="pi pi-building text-base lg:text-2xl mr-2 lg:mr-0"></i>
                      <span class="block lg:hidden font-medium">Return to Venues</span>
                  </a>
                </li>
                <li>
                    <a (click)="auth.logout({ logoutParams: { returnTo: 'https://' + this.doc.location.host } })" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors">
                        <i class="pi pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Logout</span>
                        
                    </a>
                </li>
            </ul>
        </div>
        <div class="p-3" style="background-color:white; ">
            <div class="flex-auto">

                <router-outlet></router-outlet>
            </div>
        </div>
        <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
             <span style="font-size:17px;">&copy; copyright 2023 Smart Entertainment Ltd  -  Version: {{version + "/" + BackendVersion}}</span>
        </div>
    </div> -->

    <div class="min-h-screen flex surface-ground" style="width: 100% !important">
      <div id="app-sidebar-1" class="surface-section h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 surface-border select-none" style="width:280px">
          <div class="flex flex-column h-full">
            <div class="flex align-items-center px-5 flex-shrink-0" style="height:60px; margin-top:20px;">
              <img src="assets/images/wannabook.png" alt="Image" height="60">
          </div>

          <div *ngIf="ShowBC" style="text-align: right; margin-bottom: 20px;margin-left:20px;">
              <span class="p-input-icon-left ml-auto" style="margin-right:20px;"> 
                  <input pInputText type="text" placeholder="Search Bookings" style="height: 40px; padding-left:33px;width: 240px; margin-right: 10px;" [(ngModel)]="searchCriteria" />
              </span>
              <button pButton type="button" style="height: 40px;width: 40px;margin-right: 19px;margin-top: -40px;float: right;" class="p-button-sm p-element p-ripple p-button-outlined p-component" (click)="getSearchResults()">
                <span class="pi pi-search" style="margin-left:-6px;"></span>
                </button>
          </div>
      
          <div class="overflow-y-auto">
              <p-menu [model]="items"></p-menu>
              <!-- <p-tieredMenu [model]="items" styleClass="surface-section"></p-tieredMenu> -->
          </div>

          </div>
      </div>
      <div class="min-h-screen flex flex-column relative flex-auto">
          <div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style="height:60px">
              <div class="flex">
                  <a pRipple style="text-decoration: none;" class="cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700 mr-3" pStyleClass="#app-sidebar-1" enterClass="hidden" enterActiveClass="fadeinleft" leaveToClass="hidden" leaveActiveClass="fadeoutleft" [hideOnOutsideClick]="true">
                      <i class="pi pi-bars text-4xl"></i>
                  </a>
                  <span style="font-size:28px; font-weight: lighter;;">Welcome:&nbsp;{{vsVenueName}}</span>
              </div>

              <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" enterActiveClass="fadein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                  <i class="pi pi-ellipsis-v text-2xl"></i>
              </a>
              <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row 
                  surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                  <li>
                    <a *ngIf="!businessOwner" (click)="backToPin()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors">
                        <i class="pi pi-th-large text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Return to Pin Pad</span>
                    </a>
                  </li>
  
                  <li>
                    <a *ngIf="!businessOwner && ShowChangePassword" (click)="manageUserPassword()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors">
                        <i class="pi pi-unlock text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Manage Password</span>
                    </a>
                  </li>
  
                  <li>
                    <a *ngIf="businessOwner" (click)="backToVenues()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors">
                        <i class="pi pi-building text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Return to Venues</span>
                    </a>
                  </li>
                  <li>
                      <a (click)="logout()" pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                          transition-duration-150 transition-colors">
                          <i class="pi pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0"></i>
                          <span class="block lg:hidden font-medium">Logout</span>
                          
                      </a>
                  </li>
              </ul>
          </div>
          <div class="flex flex-column flex-auto" style="background: white;">
              <div style="padding: 0px !important">
                <router-outlet></router-outlet>
              </div>
          </div>
          <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
            <span style="font-size:17px;">&copy; copyright 2024 Smart Entertainment Ltd  -  Version: {{version + "/" + BackendVersion}}</span>
          </div>
      </div>
  </div>
</div>

<ng-template #loggedOut>
  <div class="min-h-screen flex surface-ground">
    <div class="min-h-screen flex flex-column relative flex-auto">
        <div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style="height:92px">
            <div class="flex">
              <img src="https://dashboard.wannabook.online/assets/images/wannabook.png" alt="Image" class="mr-0 lg:mr-6" height="60">
            </div>
            <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" enterActiveClass="fadein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                <i class="pi pi-ellipsis-v text-2xl"></i>
            </a>
            
            <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row 
                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                <li>
                  <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: darkgreen !important; border-color: darkgreen !important;margin-top: 0px !important;" (click)="auth.loginWithRedirect();">
                    Log in
                  <span class="pi pi-download text-base lg:text-2xl mr-2 lg:mr-0" style="margin-left:10px; transform: rotate(-90deg);-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);-ms-transform: rotate(-90deg);-o-transform: rotate(-90deg);filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);"></span>
                  </button>
                </li>
            </ul>
        </div>
        <div>
          <div class="block-header">
            <div class="block-content">
                <div class="ng-star-inserted">
                    <div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
                        <div class="flex flex-wrap">
                            <div class="w-12 lg:w-6 p-4">
                            <h1 class="text-6xl font-bold text-white mt-0 mb-3">WannaBook, the booking system that helps you grow faster
                            </h1>
                            <p class="text-3xl text-gray-400 mt-0 mb-5">Our unique online booking platforms built with over 25 years experience in the industry. Ideal for all activity based businesses, we offer a flexible, customised and comprehensive online booking system to meet your needs.</p>
                            <ul class="list-none p-0 m-0">
                                <li class="mb-3 flex align-items-center">
                                <i class="pi pi-thumbs-up text-yellow-500 text-xl mr-2"></i>
                                <span class="text-gray-400 line-height-3">Easy to use.</span>
                                </li>
                                <li class="mb-3 flex align-items-center">
                                <i class="pi pi-question-circle text-yellow-500 text-xl mr-2"></i>
                                <span class="text-gray-400 line-height-3">Free UK based support.</span>
                                </li>
                                <li class="mb-3 flex align-items-center">
                                <i class="pi pi-download text-yellow-500 text-xl mr-2"></i>
                                <span class="text-gray-400 line-height-3">Free updates.</span>
                                </li>
                            </ul>
                            <button type="button" pbutton="" pripple="" label="Sign up now" class="p-element p-ripple block mt-7 mb-7 lg:mb-0 p-button-success p-button-lg font-medium p-button p-component" (click)="doEmail()">
                                <span class="p-button-label">Email us for more information</span>
                                <span class="p-ink"></span>
                            </button>
                            </div>
                            <div class="w-12 lg:w-6 text-center lg:text-right overflow-hidden">
                                <img src="https://blocks.primeng.org/assets/images/blocks/hero/hero-illustration.svg" alt="Image" class="w-full lg:w-auto">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div>
            <div class="ng-star-inserted">
                <div class="surface-900 py-6 lg:py-4 md:px-6 lg:px-8 flex flex-column lg:flex-row justify-content-between align-items-center">
                  <div class="flex align-items-center flex-order-0 lg:flex-order-1">
                    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
                      <i class="pi pi-facebook surface-section p-1 text-sm border-circle text-900"></i>
                    </a>
                    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
                      <i class="pi pi-twitter surface-section p-1 text-sm border-circle text-900"></i>
                    </a>
                    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
                      <i class="pi pi-youtube surface-section p-1 text-sm border-circle text-900"></i>
                    </a>
                    <a tabindex="0" class="cursor-pointer lg:mt-0 block">
                      <i class="pi pi-google surface-section p-1 text-sm border-circle text-900"></i>
                    </a>
                  </div>
                </div>
              </div>
        </div>
        
        </div>
    </div>
  </div>
</ng-template>
